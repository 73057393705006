import './companyNews.less';
import { Link } from "react-router-dom";

export default function CompanyNews() {
  // const data = [
  //   {
  //     img: 'https://lanhu-oss.lanhuapp.com/SketchPng84b3ba535d0798be7377017792c0c87ff7bd9526016180772e8bf52899cec7bb',
  //     date: '2024-09-08',
  //     title: '集视医疗亮相中华医学会第二十八次眼科学术大会',
  //     route: '/newsInfo/academic',
  //   },
  //   {
  //     img: 'https://lanhu-oss.lanhuapp.com/SketchPngb5d7e57fc3b4b81ef652f1d3b7de706dd3ccd0070679ada4d529a742d0309ebb',
  //     date: '2024-09-08',
  //     title: '复健资本独家投资丨集视医疗完成Pre-A轮融资',
  //     route: '/newsInfo/pre',
  //   },
  //   {
  //     img: 'https://lanhu-oss.lanhuapp.com/SketchPng69598d7bb8c49fba82ac310cc0723ab54edf9bed9aabbfb4685bd0020dca4c87',
  //     date: '2024-09-08',
  //     title: '卓悦榜丨集视医疗荣膺“年度智慧医疗最佳企业”奖项',
  //     route: '/newsInfo/bestEnterprise',
  //   },
  //   {
  //     img: 'https://lanhu-oss.lanhuapp.com/SketchPng530a75f3b6e906860b3a8e183f843b57d5fd2216fc3c7b1607ddcfc7679f30fa',
  //     date: '2024-09-08',
  //     title: '集视医疗获远毅资本天使轮投资',
  //     route: '/newsInfo/invest',
  //   },
  // ]
  return <div className="CompanyNews1">
    <div className='CompanyNews1_cover'>
      <div className="CompanyNews1_cover-title">
        公司动态
      </div>
    </div>
    <div className="CompanyNews1_content">
      <div className="CompanyNews1_content-title">最新新闻</div>
      <div className='CompanyNews1_content-desc'>2024CCOS是集视医疗首次参与眼科临床的学术交流</div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Link
          to='/newsInfo/academic'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
        >
          <div className='CompanyNews1_content-img'>
            <img
              style={{ width: '100%', height: '100%', borderRadius: 12 }}
              src='./image/companyNews5.png'
              alt=''
            />
          </div>
        </Link>
      </div>
      <div className="CompanyNews1_content-title">公司动态</div>
      <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
        <div className='CompanyNews1_content-desc'>集视最新动态</div>
        <Link
          to='/allNews'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          className='CompanyNews1_content-btn'
        >
          查看全部
        </Link>
      </div>
      <div className='CompanyNews1_contentNews'>
        <Link
          to='/newsInfo/amblyopiaClinical'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          className='CompanyNews1_contentNews-item'
        >
          {/* <div className="CompanyNews1_contentNews-itemBgc"></div> */}
          <img
            src='./image/companyNews1.png'
            alt=''
          />
          <div className='CompanyNews1_contentNews-itemDesc'>集视医疗弱视方案临床试验初步访视结果出炉，001号患者4周基本治愈！</div>
          <div className='CompanyNews1_contentNews-itemDate'>2024.10.11</div>
        </Link>
        <Link
          to='/newsInfo/contract'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          className='CompanyNews1_contentNews-item'
        >
          {/* <div className="CompanyNews1_contentNews-itemBgc"></div> */}
          <img
            src='./image/companyNews2.png'
            alt=''
          />
          <div className='CompanyNews1_contentNews-itemDesc'>温州医科大学附属眼视光医院＆集视医疗战略合作签约</div>
          <div className='CompanyNews1_contentNews-itemDate'>2024.10.31</div>
        </Link>
      </div>
      <div className='CompanyNews1_contentNews'>
        <Link
          to='/newsInfo/academic'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          className='CompanyNews1_contentNews-item'
        >
          {/* <div className="CompanyNews1_contentNews-itemBgc"></div> */}
          <img
            src='./image/companyNews3.png'
            alt=''
          />
          <div className='CompanyNews1_contentNews-itemDesc'>集视医疗亮相中华医学会第二十八次眼科学术大会</div>
          <div className='CompanyNews1_contentNews-itemDate'>2024.09.04</div>
        </Link>
        <Link
          to='/newsInfo/pre'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          className='CompanyNews1_contentNews-item'
        >
          {/* <div className="CompanyNews1_contentNews-itemBgc"></div> */}
          <img
            src='./image/companyNews4.png'
            alt=''
          />
          <div className='CompanyNews1_contentNews-itemDesc'>复健资本独家投资丨集视医疗完成Pre-A轮融资</div>
          <div className='CompanyNews1_contentNews-itemDate'>2024.08</div>
        </Link>
      </div>
      {/* <div className='CompanyNews1_contentBottom'>
        {data.map((item, index) => <Link
          to={item.route}
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          key={index}
          className="CompanyNews1_contentBottomItem"
        >
          <div className="CompanyNews1_bottomItemImg">
            <img
              style={{ width: '100%', height: 'auto' }}
              src={item.img}
              alt=''
            />
          </div>
          <div className='CompanyNews1_bottomItemDesc'>
            {item.date}<br />
            {item.title}
          </div>
        </Link>)}
      </div> */}
    </div>
  </div>
}
