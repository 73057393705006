import './allNews.less';
import { Link } from "react-router-dom";

export default function AllNews() {
  const newsData = [
    {
      title: '温州医科大学附属眼视光医院＆集视医疗战略合作签约',
      date: '2024-10-31',
      desc: '2024年10月，集视医疗与温州医科大学附属眼视光医院达成了一项具有里程碑意义的战略合作协议。根据协议，集视医疗的视功能训练产品将正式入驻温州医科大学附属眼视光医院的视功能训练室…',
      img: '/image/allNews1.png',
      route: '/newsInfo/contract'
    },
    {
      title: '集视医疗弱视方案临床试验初步访视结果出炉，001号患者4周基本治愈！',
      date: '2024-10-11',
      desc: '苏州集视医疗科技有限公司（以下简称“集视医疗”）于国内由复旦大学附属眼耳鼻喉科医院牵头，联合多中心开展的全球首个“基于AR技术的全年龄段弱视治疗方案的有效性和安全性”的前瞻性…',
      img: '/image/allNews2.png',
      route: '/newsInfo/amblyopiaClinical'
    },
    {
      title: '集视医疗亮相中华医学会第二十八次眼科学术大会',
      date: '2024-09-04',
      desc: '2024年9月4-8日，中华医学会第二十八次眼科学术大会在武汉国际博览中心隆重举行，作为年度眼科行业最盛大的学术会议，全球逾万名专家、学者相聚于此，交流分享眼科和视觉科学方面最新研究成果…',
      img: '/image/allNews3.png',
      route: '/newsInfo/academic'
    },
    {
      title: '集视医疗完成Pre-A轮融资',
      date: '2024-08',
      desc: '据悉，集视医疗已于近期完成由复健资本独家投资的Pre-A轮融资。随着本轮资金的成功注入，集视医疗将大幅提升产能，不断巩固和拓展其全年龄段弱视治疗方案，扎实推进已获批产品的商业化进程…',
      img: '/image/allNews4.png',
      route: '/newsInfo/pre'
    },
    {
      title: '卓悦榜丨集视医疗荣膺“年度智慧医疗最佳企业”奖项',
      date: '2024-04-26',
      desc: '2024年4月26日，浩悦资本正式发布了“2024第八届医疗健康投资卓悦榜”，集视医疗荣膺“年度智慧医疗最佳企业”奖项。',
      img: '/image/allNews5.png',
      route: '/newsInfo/bestEnterprise'
    },
    {
      title: '集视医疗获远毅资本天使轮投资，为2700万全年龄段弱视患者提供基于XR技术的视觉训练方法',
      date: '2024-08',
      desc: '苏州集视医疗科技有限公司（简称“集视医疗”）宣布完成由远毅资本独家投资的天使轮融资。资金将用于其自研的基于混合现实XR技术的视觉训练方法的研发与落地。',
      img: '/image/allNews6.png',
      route: '/newsInfo/invest'
    },
    {
      title: 'ARVO 2017新兴趋势和热点话题丨双眼增强现实范式用于成人弱视治疗',
      date: '2024-08',
      desc: '复旦大学附属眼耳鼻喉科医院眼科孙兴怀教授、刘红主任、文雯博士与美国加州大学的李响博士合作，将增强现实和双眼训练相结合，研发了一种双眼分视的训练新模式并用于成年弱视…',
      img: '/image/allNews7.png',
      route: '/newsInfo/arvo'
    },
  ]

  return <div className="AllNews">
    <div className='AllNews_bgc1'></div>
    <div className='AllNews_bgc2'></div>
    <div className='AllNews_bgc3'></div>
    <div className='AllNews_bgc4'></div>
    <div className='AllNews_bgc5'></div>
    <div className="AllNews_title">全部动态</div>
    <div className="AllNews_content">
      {newsData.map((item, index) => <Link
        key={index}
        to={item.route}
        className="AllNews_contentItem"
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <div className='AllNews_contentItem-img'>
          <img
            style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
            src={item.img}
            alt=''
          />
        </div>
        <div className='AllNews_contentItem-right'>
          <div className='AllNews_contentItem-rightTitle'>{item.title}</div>
          <div className='AllNews_contentItem-rightDate'>{item.date}</div>
          <div className='AllNews_contentItem-rightDesc'>{item.desc}</div>
        </div>
      </Link>)}
    </div>
  </div >
}
