import { Link } from 'react-router-dom';
import './sciencePopularizationInfo.less';

export default function IdentifyAmblyopia() {
  return <div className="SciencePopularizationInfo">
    <div className='SciencePopularizationInfo_bgc1'></div>
    <div className='SciencePopularizationInfo_bgc2'></div>
    <div className='SciencePopularizationInfo_bgc3'></div>
    <div className='SciencePopularizationInfo_bgc4'></div>
    <div className='SciencePopularizationInfo_bgc5'></div>
    <div className="SciencePopularizationInfo_content">
      <Link
        to='/fcst/sciencePopularization'
        className="SciencePopularizationInfo_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有科普文章
      </Link>
      <div className="SciencePopularizationInfo_content-title">【科普】什么是弱视？</div>
      <div className="SciencePopularizationInfo_content-date">2024-10-31</div>
      <div className="SciencePopularizationInfo_content-line"></div>
      <div className="SciencePopularizationInfo_content-subTitle">什么是弱视？</div>
      <div className="SciencePopularizationInfo_content-desc">
        弱视是一种视觉发育相关疾病，其特征为戴镜视力达不到同龄儿童正常视力下线或者两眼视力相差2行以上，且经眼科检查并未发现能够引起视力差的器质性病变。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="SciencePopularizationInfo_content-img">
          <img
            style={{ width: '100%', height: '100%',}}
            src='/image/identifyAmblyopia1.png'
            alt=""
          />
        </div>
      </div>
      <div className="SciencePopularizationInfo_content-subTitle">哪些孩子容易弱视？</div>
      <div className="SciencePopularizationInfo_content-desc">
        · 斜视<br />
        · 屈光不正<br />
        · 一些影响视力的眼部疾病（如白内障）
      </div>
      <div className="SciencePopularizationInfo_content-subTitle">怎么发现自己的孩子患有弱视？</div>
      <div className="SciencePopularizationInfo_content-desc">
        单纯的弱视通常没有明显的症状，并且发病较早，患儿不能清楚表达视力的缺陷，因此大多数的弱视都是在常规视力筛查中发现的；<br />
        若患儿立体视力缺损较为严重，会在早期出现对物品的距离把握不准的现象，表现为不能准确拿到不同距离的物品等，但随着患儿成长，
        逐步会利用其他细节（如大小，深度等）判断距离则不容易观察到该症状。因此一旦发现孩子出现类似的倾向，应及时去往正规的眼科医院检查；<br />
        此外由斜视或者白内障等眼病引起的弱视，一般都是先发现斜视等原发疾病，再进一步确诊弱视。
      </div>
      <div className="SciencePopularizationInfo_content-subTitle">弱视应该如何治疗？</div>
      <div className="SciencePopularizationInfo_content-desc">
        弱视的治疗应在对儿童管理眼部疾病经验丰富的眼科医生引导下进行。<br />
        大部分弱视的患者都应该优先治疗原发疾病，包括矫正屈光度（眼镜或隐形眼镜）、摘除白内障等，<br />
        在消除病因之后就应该开始弱视治疗，目前应用最广泛的治疗手段是单眼遮盖，通过压抑好眼睛（遮盖、压抑膜），弱化好眼的注视功能，
        让出机会给差眼，刺激差眼锻炼，增强功能，以提高视力。但是遮盖法本身存在一定的局限性，一个是对12岁以上的患者几乎无效，
        另一个则是无法有效帮助弱视患者建立立体视等高阶视功能。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="SciencePopularizationInfo_content-img">
          <img
            style={{ width: '100%', height: '100%', }}
            src='/image/identifyAmblyopia2.png'
            alt=""
          />
        </div>
      </div>
      <div className="SciencePopularizationInfo_content-subTitle">ARTA 埃尔塔™ 是什么？</div>
      <div className="SciencePopularizationInfo_content-desc">
        集视医疗针对目前弱视治疗的不足，开发出的全年龄弱视治疗仪，是目前市面上唯一一款疗效优于遮盖法的弱视治疗产品，并且突破了既往临床弱视治疗的年龄限制，将治疗年龄拓宽到了55周岁。<br />
        埃尔塔™已于2024年10月上市，为更多临床“不可治愈”的弱视患者带来新的希望。
      </div>
      <div className="SciencePopularizationInfo_content-line"></div>
    </div>
  </div>
}
