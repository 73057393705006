import './glaucoma.less';

export default function Glaucoma() {
  return <div className="Glaucoma">
    <div className='Glaucoma_cover'>
      <div className="Glaucoma_cover-title">创新青光眼治疗方案</div>
    </div>
    <div className="Glaucoma_content">
      <div className="Glaucoma_content-title">集视医疗青光眼治疗方案</div>
      <div className="Glaucoma_content-desc">集视医疗正在开发一种青光眼治疗设备，无需手术和药物治疗即可修复青光眼患者的缺损视野。</div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="Glaucoma_content-img">
          <div className="Glaucoma_content-imgBgc"></div>
          <img
            style={{ width: '80%', height: '100%'}}
            src='./image/glaucoma.png'
            alt=''
          />
        </div>
      </div>
    </div>
  </div>
}
