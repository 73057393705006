import './arvo.less';
import { Link } from 'react-router-dom';

export default function Arvo() {
  return <div className="Arvo">
    <div className='Arvo_bgc1'></div>
    <div className='Arvo_bgc2'></div>
    <div className='Arvo_bgc3'></div>
    <div className='Arvo_bgc4'></div>
    <div className='Arvo_bgc5'></div>
    <div className="Arvo_content">
      <Link
        to='/allNews'
        className="Arvo_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有动态
      </Link>
      <div className="Arvo_content-title">ARVO 2017新兴趋势和热点话题丨双眼增强现实范式用于成人弱视治疗</div>
      <div className="Arvo_content-date">2024-08</div>
      <div className="Arvo_content-line"></div>
      <div className="Arvo_content-desc">
        复旦大学附属眼耳鼻喉科医院眼科孙兴怀教授、刘红主任、文雯博士与美国加州大学的李响博士合作，将增强现实和双眼训练相结合，研发了一种双眼分视的训练新模式并用于成年弱视，
        训练模式包括一个带有双镜头的头戴式显示器和处理器，该双镜头实时捕捉环境图像，经处理器处理后分别投射到双眼前的显示屏上，其中弱视眼和健眼接受的图像对比度不同，
        并且双眼图像信息完整性不一（如下图）。患者佩戴该显示器后进行正常生活工作，需要整合双眼信息后才可以完成日常视觉任务从而达到弱视眼的视觉训练。经过四周左右的训练，
        大部分患者的弱视眼视力和不良的双眼间抑制均有所改善。该研究的初步结果（A dichoptic augmented-reality paradigm as a treatment for adult amblyopes）在2017年ARVO作大会发言交流，
        并被ARVO评为热点（Hot Topic）之一。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='Arvo_content-img1'
          src='/image/arvo1.png'
          alt=""
        />
      </div>
      <div className="Arvo_content-desc">
        视觉系统由出生到成熟需要经历漫长的发育过程，婴儿出生时，视力不及人的1%，随着年龄的不断增长，
        双眼视细胞不断发育和完善。6岁以内是视功能发育的重要时期，如这个时期某种原因造成双眼视物障碍，
        婴幼儿被迫接受异常的视觉输入，导致视觉通路的发育异常，弱视便会发生。
        <br />
        <br />
        由于弱视是视觉发育相关疾病，因此弱视的治疗与年龄相关，年龄越小，疗效越好，传统观念认为错过视觉
        敏感期（12岁以下）的弱视患者疗效较差，可能造成终生视觉缺陷。然而，无论患者的年龄大小都应当进行治疗。
        <br />
        <br />
        弱视临床治疗”金标准“——遮盖疗法已有200余年历史，迄今为止依旧被认为是最有效的弱视治疗手段，
        但其仍存在诸多弊端，如：依从性不佳、易回退、用于大龄青少年和成人弱视治疗几乎无效等。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='Arvo_content-img2'
          src='/image/arvo2.png'
          alt=""
        />
      </div>
      <div className="Arvo_content-desc">
        遮盖疗法的原理是通过遮盖优势眼从而强迫弱视眼使用，一般认为传统的遮盖治疗对成人弱视患者无效，但是最新生理学研究提示成人大脑仍具有可塑性。
        这明显与目前成人弱视治疗几乎无效不相符。近年来一系列研究提示单眼弱视的原发病因不再是双眼同视功能异常（binocular dysfunction），
        即弱视患者患弱视眼的视力障碍是继发于异常的双眼间抑制（interocular suppression）。目前常用的遮盖疗法或单眼知觉学习均旨在重塑弱视眼视路而忽略了作为原发因素的不良双眼交互作用，
        这有可能是传统治疗方法效果回退或不能达到视力完全康复的主要原因，因此解除弱视患者的眼间抑制，帮助弱视眼建立视觉解析能力可能是治疗成人单眼弱视的关键因素。
      </div>
      <div className="Arvo_content-line"></div>
    </div>
  </div>
}
