import './invest.less';
import { Link } from 'react-router-dom';

export default function Arvo() {
  return <div className="Invest">
    <div className='Invest_bgc1'></div>
    <div className='Invest_bgc2'></div>
    <div className='Invest_bgc3'></div>
    <div className='Invest_bgc4'></div>
    <div className='Invest_bgc5'></div>
    <div className="Invest_content">
      <Link
        to='/allNews'
        className="Invest_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
        />
        查看所有动态
      </Link>
      <div className="Invest_content-title">集视科技获远毅资本天使轮投资，为2700万全年龄段弱视患者提供基于XR技术的视觉训练方法</div>
      <div className="Invest_content-date">2024-08</div>
      <div className="Invest_content-line"></div>
      <div className="Invest_content-desc">
        苏州集视医疗科技有限公司（简称“集视医疗”）宣布完成由远毅资本独家投资的天使轮融资。资金将用于其自研的基于混合现实XR技术的视觉训练方法的研发与落地。<br />
        集视医疗成立于2020年，是一家以产学研联动为抓手，致力于常见眼病治疗与防控的整体解决方案的高新技术企业。当前公司产品已覆盖了弱视、干眼和眼末端患者的需求，并在弱视治疗领域的数字疗法形成了自主知识产权。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='Invest_content-img1'
          src='/image/invest1.png'
          alt=""
        />
      </div>
      <div className="Invest_content-desc">
        我国弱视患者比例超5%，传统遮盖疗法疗效有限，对于大龄患者接近无效
        <br />
        弱视是发生在儿童时期的常见眼病，也是影响儿童视觉发育的主要眼病之一。通常是在视觉发育早期竞争发育的双眼刺激输入失去平衡引发的结果。
        弱视不仅会导致最佳矫正视力下降，还会导致注视异常、色觉及暗适应异常，甚至在儿童视觉发育的敏感期和关键期引起立体视发育异常，严重影响儿童身心健康。
        <br />
        <br />
        传统遮盖治疗虽简便易行，但存在疗效有限（部分患者在长时间遮盖后仍无法达到正常视力或在遮盖治疗停止后发生一定程度的回退）、双眼功能缺陷、立体视功能受损等问题，
        并且遮盖会对患儿外观及生活产生影响，可能导致自卑、不合群等群里问题，实际依从性较差，治疗效果往往难尽人意，且易复发。
        <br />
        <br />
        一般来讲，弱视治疗在12岁是一个临界点，12岁以后再治疗效果微乎其微。长久以来，在弱视临床研究领域，人们对12岁以后的弱视（在12岁前治疗失败或未得到有效治疗者）几乎不采取任何治疗措施。
        弱视如得不到有效治疗，则可引起永久性视觉损失。
      </div>
      <div className="Invest_content-subTitle">牵手中科院研发基于混合现实XR技术的视觉训练方法</div>
      <div className="Invest_content-desc">
        弱视的发病机制较为复杂，通常不仅是眼睛问题，还与视觉信息的传导与脑中枢的视觉感知有关。
        <br />
        集视医疗和中科院合作引入磁共振技术发现，弱视患者有小细胞层损伤，这类细胞主要介导精细觉、颜色，所以弱视患者的精细分辨率或视力更差。
        集视医疗首次活体完整揭示弱视患者的神经环路损害，并首次提出“双机制假说”，即在单眼状态下，有一个上行的小细胞通路的损伤，在患者双眼一起工作时，
        好的眼睛会对坏的眼睛有一个强的抑制作用，从而加重损伤。
        <br />
        <br />
        基于以上理论，集视医疗联合国内知名眼科临床专家联合研发了基于混合现实XR技术的视觉训练方法，通过特定的图像刺激算法重新激活弱视患者的细胞层修复，
        从而实现弱视的视力恢复，且效果面向全年龄段有效，是弱视领域的重大突破。
        <br />
        该视觉训练方案主要包含两方面，一方面是硬件的革新，利用一些日常情景，比如佩戴混合现实XR眼镜进行自由学习、工作、吃饭等，不影响正常生活，大大提高患者的依从性；
        另一方面是训练机制的革新，联合双眼推拉训练，针对双机制损伤训练，可替代传统遮盖疗法治疗大龄儿童和成人弱视甚或达到治愈的目的。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='Invest_content-img2'
          src='/image/invest2.png'
          alt=""
        />
      </div>
      <div className="Invest_content-desc">
        对于本次融资，集视医疗创始人童晓煜表示：“集视医疗将高分辨率功能磁共振引入青光眼、弱视等神经性眼病机制研究解决临床问题，未来我们也将研发多项应用于眼病诊疗的新范式。
        我们希望可以利用科研手段更真实客观的揭示疾病机制，破解临床诊疗难题，同时将研究成果转化为创新性技术，依据市场落地产品，依据患者使用和恢复情况不断调整技术形态，
        形成完整闭环。集视医疗未来还会瞄准和布局更多的眼科赛道，结合脑认知和临床研究解决更多复杂问题。”
        <br />
        远毅资本投资副总裁李阳先生表示：“集视医疗由全球领先的脑科学、眼科临床、可穿戴技术深度融合的产学研团队组成，结合脑科学深入研究眼科相关疾病的发病机制并设计针对性诊疗范式，
        开发基于可穿戴设备、符合临床路径、个性化、高依从性的诊疗产品。从成人弱视切入，未来拓展到低视力、青光眼、干眼等全方位眼健康数字化管理，全面提升眼科治疗的整体水平，
        改善患者的生活质量，弥补当前眼科治疗的短板。”
      </div>
      <div className="Invest_content-subTitle">关于远毅资本</div>
      <div className="Invest_content-desc">
        远毅资本成立于2016年，是一家专注于数字医疗技术领域的早期风险投资基金。目前，管理的多支人民币和美元基金累计投资管理规模超过30亿人民币。
        <br />
        远毅深耕数字医疗早期和增长期的投资机会，在数字医疗的高效触达、创新支付以及效率提升这三大基础设施方面实现前瞻性布局。
        远毅资本已在拥有核心技术和创新商业模式的医疗器械和设备、精准诊断、移动医疗和服务以及医疗保险科技公司。
        近几年投资的项目主要包括AI医学影像龙头数坤科技，医疗创新支付领导者镁信健康，齿科3D打印设备公司迅实科技，医疗器械B2B流通平台贝登医疗，五官科介入器械企业莱诺医疗等。
      </div>
      <div className="Invest_content-line"></div>
    </div>
  </div>
}
