import { Link } from 'react-router-dom';
import './solutionIndex.less';

export default function SolutionIndex() {
  const data = [
    {
      title: 'ARTA 埃尔塔™',
      subTitle: '全年龄弱视治疗仪',
      img: './image/solutionIndex1.png',
      route: '/arta',
    },
    {
      title: 'ARGOS 爱格视™',
      subTitle: '全功能3D近视训练仪',
      img: './image/solutionIndex2.png',
      route: '/argos',
    },
    {
      title: 'EYEPAL 眼派™',
      subTitle: '可视温润舒眼仪',
      img: './image/solutionIndex3.png',
      route: '/eyepal',
    },
  ]

  const solutionData = [
    {
      title: '斜视术后康复数字疗法',
      img: './image/solutionIndex4.png',
      route: '/strabismus',
    },
    {
      title: '创新青光眼治疗方案',
      img: './image/solutionIndex5.png',
      route: '/glaucoma',
    },
  ]

  return <div className="SolutionIndex">
    <div className='SolutionIndex_cover'>
      <div className="SolutionIndex_cover-title">产品及解决方案</div>
    </div>
    <div className="SolutionIndex_content">
      <div className="SolutionIndex_content-bgi1"></div>
      <div className="SolutionIndex_content-bgi2">
        <img
          style={{ width: '100%', height: '100%', }}
          src='./image/bgi2.png'
          alt=''
        />
      </div>
      <div className="SolutionIndex_content-bgi3">
        <img
          style={{ width: '100%', height: '100%', }}
          src='./image/bgi2.png'
          alt=''
        />
      </div>
      <div className="SolutionIndex_content-bgi4"></div>
      <div className="SolutionIndex_content-bgi5"></div>
      {/* <div className="SolutionIndex_content-bgi6">
        <img
          style={{ width: '100%', height: '100%', }}
          src='./image/bgi2.png'
          alt=''
        />
      </div> */}
      <div className="SolutionIndex_content-bgi7"></div>
      <div className="SolutionIndex_content-top">
        <div className="SolutionIndex_content-title">产品</div>
        <div className="SolutionIndex_content-desc">集视医疗产品</div>
      </div>
      {data.map((item, index) => <Link
        key={index}
        to={item.route}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
        className={`SolutionIndex_content-item ${index === 0 ? 'first-item' : ''}`}
      >
        <div className="SolutionIndex_content-itemImg">
          <img
            style={{ width: '100%', height: '100%', position: 'absolute' }}
            src={item.img}
            alt=''
          />
          <div className="SolutionIndex_content-itemContent">
            <div className="SolutionIndex_content-itemTitle">{item.title}</div>
            <div className="SolutionIndex_content-itemSubTitle">{item.subTitle}</div>
          </div>
        </div>
      </Link>)}
      <div className="SolutionIndex_content-top">
        <div className="SolutionIndex_content-title">解决方案</div>
        <div className="SolutionIndex_content-desc">集视医疗全新解决方案</div>
      </div>
      {solutionData.map((item, index) => <Link
        key={index}
        to={item.route}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
        className={`SolutionIndex_content-item ${index === 0 ? 'first-item' : ''}`}
      >
        <div className="SolutionIndex_content-itemImg1">
          <img
            style={{ width: '100%', height: '100%', position: 'absolute' }}
            src={item.img}
            alt=''
          />
          <div className="SolutionIndex_content-itemTitle1">{item.title}</div>
        </div>
      </Link>)}
    </div>
  </div>
}
