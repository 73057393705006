import './bestEnterprise.less';
import { Link } from 'react-router-dom';

export default function BestEnterprise() {
  return <div className="BestEnterprise">
    <div className='BestEnterprise_bgc1'></div>
    <div className='BestEnterprise_bgc2'></div>
    <div className='BestEnterprise_bgc3'></div>
    <div className='BestEnterprise_bgc4'></div>
    <div className='BestEnterprise_bgc5'></div>
    <div className="BestEnterprise_content">
      <Link
        to='/allNews'
        className="BestEnterprise_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有动态
      </Link>
      <div className="BestEnterprise_content-title">卓悦榜丨集视医疗荣膺“年度智慧医疗最佳企业”奖项</div>
      <div className="BestEnterprise_content-date">2024-04-26</div>
      <div className="BestEnterprise_content-line"></div>
      <div className="BestEnterprise_content-desc">
        2024年4月26日，浩悦资本正式发布了“2024第八届医疗健康投资卓悦榜”，集视医疗荣膺“年度智慧医疗最佳企业”奖项。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='BestEnterprise_content-img1'
          src='/image/bestEnterprise1.png'
          alt=""
        />
      </div>
      <div className="BestEnterprise_content-desc">
        作为业界高度认可的年度权威榜单，医疗健康投资卓悦榜自2017年首次发榜八年以来，始终秉承公平公正、客观中立的原则，针对创新医药、创新医疗器械、IVD与智慧医疗、健康服务、智慧医疗、生命科技等不同领域的医疗健康优秀企业授予奖项，
        旨在打造真正具有公信力和影响力的投资榜单，为行业发展树立新标杆，为投资合作创造新机遇。集视医疗自成立以来深耕于弱视、干眼和眼末端疾病患者的需求，以产学研联动为抓手，致力于常见眼病治疗与防控的整体解决方案的高新技术企业。
        公司产品已覆盖弱视、青少年儿童近视防控、干眼防治，并在弱视治疗领域的数字疗法形成了几十项自主知识产权。2022-2024年期间，公司累计获得远毅资本、复星集团等医疗领域巨头资本数千万投资。共荣获30余项奖励，和中国科学院、复旦大学附属眼耳鼻喉科医院达成深度战略合作，承担重大课题研究任务。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='BestEnterprise_content-img2'
          src='/image/bestEnterprise2.png'
          alt=""
        />
      </div>
      <div className="BestEnterprise_content-desc">
        风物长宜放眼量。中国医疗健康行业虽处在调整周期，每年却仍有着超千例融资交易发生，一例例开拓创新疆界的产业升级案例仍在不断浮现。正因此，集视医疗将持续秉持患者需求至上，创新精准医疗的价值观，
        以脑科学研究为基础载体运用领先的物联网、人工智能与大数据云计算技术，打通院内外及患者全时空全病程数据，全面赋能眼科疾病的诊断、治疗及诊后管理。致力于成为全球顶尖的脑科学机制研究驱动的眼科诊疗平台。
        大道不孤，众行致远，集视医疗期待和浩悦资本及其他产业同仁携手共进，穿越周期，共同谱写中国医疗健康事业高质量发展的时代画卷。
      </div>
      <div className="BestEnterprise_content-line"></div>
    </div>
  </div>
}
