import { Link } from 'react-router-dom';
import './literatureInfo.less';

export default function BreakthroughTheory() {
  return <div className="LiteratureInfo">
    <div className='LiteratureInfo_bgc1'></div>
    <div className='LiteratureInfo_bgc2'></div>
    <div className='LiteratureInfo_bgc3'></div>
    <div className='LiteratureInfo_bgc4'></div>
    <div className='LiteratureInfo_bgc5'></div>
    <div className="LiteratureInfo_content">
      <Link
        to='/fcst/literature'
        className="LiteratureInfo_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有文献
      </Link>
      <div className="LiteratureInfo_content-title">方法突破 - 结合增强现实的，可以针对弱视眼P通路进行矫正的双眼图像算法</div>
      <div className="LiteratureInfo_content-date">2024-10-31</div>
      <div className="LiteratureInfo_content-line"></div>
      <div className="LiteratureInfo_content-subTitle">大/小细胞通路之间的推拉训练</div>
      <div className="LiteratureInfo_content-desc">
        Xu等人在2010年证明了通过使用推拉（Push-pull）方法来减少眼优势（sensory eye dominance）的可能性。在训练过程中（图 C），受试者的任务是辨别非优势眼看到中的光栅朝向。
        刺激首先向受试者的非优势眼呈现一个矩形框（注意提示），然后分别向双眼呈现一对正交光栅。注意提示会短暂激活受试者非优势眼的注意力，使得非优势眼光栅被首先感知，
        而优势眼用于干扰的光栅感知则被提示所激活的眼间抑制效应而抑制。只有在刺激呈现的后半段，优势眼中的干扰光栅才会被感知。<br />
        除了推拉方法之外，研究者在控制组还使用了仅推（Push-only）方法（图D），该方法与推拉方法类似，只是不对优势眼进行干扰刺激。两种方法的相似之处在于，
        它们都会刺激非优势眼来感知光栅刺激（Push），这种重复刺激会增强非优势眼通道的响应。相比之下，在推拉方法中，除了增强非优势眼的响应之外，
        被试也需要反复地主动抑制来自优势眼通路的干扰。在这个过程中，非优势眼对优势眼（Pull）的抑制也会得到增强。故此，额外的“Pull”动作使得推拉方法在减少眼优势方面比仅推方法更加有效。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="LiteratureInfo_content-breakthroughMethodImg">
          <img
            style={{ width: '100%', height: '100%' }}
            src='/image/breakthroughMethod1.png'
            alt=""
          />
        </div>
      </div>
      <div className="LiteratureInfo_content-desc">
        实验结果也证明了推拉方法比仅推方法对眼优势和立体视觉产生更强的训练效果。推拉方法的有效性主要归因于在训练期间，非优势眼和优势眼被同时刺激，
        优势眼中的干扰刺激需要被眼间抑制机制主动抑制。反之在仅推方法中，优势眼不受干扰，因此不需要主动地进行眼间抑制。
        因此，该研究结果表明眼间抑制在成人双眼视觉系统的可塑性中发挥着重要作用。推拉方法（相对于仅推方法）可以更有效地减少眼优势，
        这对于开发治疗弱视（眼优势的一种极端形式）的新范例具有重要意义。传统的弱视治疗主要是对优势眼进行剥夺。而最近的弱视研究发现，
        对弱视眼进行广泛的知觉训练也可以显著改善其视觉功能。然而，现有的大多数治疗方式都是基于仅推方法的原理而进行的设计，其中并不包括主动抑制优势眼的过程。
        而上述研究结果表明，采用推拉原理设计的弱视治疗可能会取得更好的治疗效果。<br />
        模仿双眼之间进行推拉的思路，本产品的设计将训练针对的存在相互作用的视觉通路由双眼通路迁移至大/小细胞通路。我们将大细胞通路偏好的刺激变为噪音，
        从而使得被试需要在训练过程中去主动抑制来自大细胞通路的干扰，同时正常呈现小细胞通路偏好的刺激。具体在图像处理上，
        我们将图像中的高空间频率的成分保留，将低空间频率的成分转换为噪音，同时以高时间频率进行闪烁。这样，我们就在大/小细胞通路之间实现了推拉的效果，
        从而针对性地去提高小细胞通路的敏感度，达到锐化成人的视力的目的。
      </div>
      <div className="LiteratureInfo_content-subTitle">结合增强现实的双眼训练</div>
      <div className="LiteratureInfo_content-desc">
        近期，Hess等人针对改善双眼视力又提出了双眼分视训练（Dichoptic training）的方法。该方法不需要进行传统的单眼遮盖，相反，研究者在弱视患者的弱视眼中呈现了高对比度的刺激，
        而在另一只眼中呈现了较低对比度的刺激，这样设计的目的在于平衡两只眼睛的输入的同时也能实现双眼的整合（可以理解为优势眼在低对比度下，对弱视眼产生了更少的抑制）。
        初步研究表明，双眼分视训练方法可以在无需遮挡弱视眼的情况下，显著改善弱视眼的立体视觉和视锐度。为了使训练更具吸引力，最近的研究使用了专门设计的视频游戏。
        游戏采用俄罗斯方块，训练过程中的任务是对下落的方块进行镶嵌。游戏中的一些方块以高对比度呈现给弱视眼，而另一些方块则以低对比度呈现给优势眼。使用这种方法的训练可以使患者在游戏过程中逐渐减小双眼间的差异，
        因为在训练过程中弱视眼需要对双眼视觉做出更大的贡献。并且研究还发现，这种包含双眼整合的方式还可以进一步改善立体视觉。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="LiteratureInfo_content-breakthroughMethodImg2">
          <img
            style={{ width: '100%', height: '100%' }}
            src='/image/breakthroughMethod2.png'
            alt=""
          />
        </div>
      </div>
      <div className="LiteratureInfo_content-imgTip">用于研究双眼可塑性的常见范式示意图</div>
      <div className="LiteratureInfo_content-desc">
        注：（A）在双眼适应过程中，一只眼睛观看包含特定空间频率和朝向的简单图案（左眼）。另一只眼观看的是对比度为零的图像。
        （B）在短期单眼剥夺范式中，一只眼睛（左眼）接受自然环境的输入，而另一只眼睛则被遮盖以消除所有图像。
        （C）在针对弱视的双眼分视训练范式中，刺激物的外观是多变的，但通常情况下，两只眼睛分别看到的是刺激物的一部分，
        必须将其整合才能完成任务（如图所示的俄罗斯方块游戏）。训练过程中优势眼（右眼）的对比度降低，以避免弱视眼受到眼间抑制。<br />
        图引自（Başgöze et al.，2018）
      </div>
      <div className="LiteratureInfo_content-desc">
        受双眼训练范式的启发，本产品也针对优势眼进行了特殊的图像算法设计。为了达到平衡其双眼的目的，我们进一步在推拉大/小细胞通路的基础上结合了双眼分视训练方法。
        为了减轻优势眼信号对非优势眼信号的抑制作用，我们将优势眼中高空间频率信号的强度进行了降低。因为两只眼睛都有高空间频率信息呈现，被试的双眼依然可以完成双眼整合。
        同时为了达到双眼高空间频率之间进行推拉的目的，我们在保留优势眼高空间频率信号的同时进一步叠加了一个过饱和的高空间频率的噪声，由于对比度适应的原因，
        过饱和的高频噪声会进一步降低优势眼训练时对高空间频率信息的敏感性，从而达到去抑制的目的。<br />
        最后，为了提高训练的时长以及被试的依从性，让被试在日常活动中也能进行长时程训练的目的。本产品通过增强现实技术来实现上述训练设计。
        在增强现实的条件下，受试者依然可以与日常环境进行互动，从而完成看书，看电影之类活动。通过增强现实技术，本产品在受试者的训练眼中呈现高空间频率信号，
        低空间频率高时间频率噪声刺激。而对于受试者的优势眼，则给予高空间频率信号，高空间频率低时间频率噪声刺激。
      </div>
      <div className="LiteratureInfo_content-subTitle">点击获取文献原文</div>
      <div className='LiteratureInfo_content-link'>
        <a
          href="https://www.cell.com/current-biology/fulltext/S0960-9822(10)01161-9"
          target='_'
        >
          https://www.cell.com/current-biology/fulltext/S0960-9822(10)01161-9
        </a>
        <a
          href="https://www.cell.com/current-biology/fulltext/S0960-9822(18)31353-8"
          target='_'
        >
          https://www.cell.com/current-biology/fulltext/S0960-9822(18)31353-8
        </a>
      </div>
      <div className="LiteratureInfo_content-line"></div>
    </div>
  </div>
}
