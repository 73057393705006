import React, { useRef, useState, useEffect, useMemo } from 'react';
import { solutionData } from '../../components/Header/Header'
import { Link, } from "react-router-dom";
import useWheelTransform from './useWheelTransform'
import useIntoViewAnimation from './useIntoViewAnimation'
import useAnimationAllEnd from './useAnimationAllEnd';
import useMouseOver from './useMouseOver';
import './index.less';

function Index() {
  // const bannerContentRef = useRef()
  // const [index, setIndex] = useState(0)
  // const [trsFlag, setTrsFlag] = useState(true)
  // const [data] = useState([
  //   {
  //     title: '脑认知治疗在视觉认知中的应用',
  //     date: '2017-12-12 18:00',
  //     desc: '大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘'
  //   },
  //   {
  //     title: '青光眼的电刺激治疗',
  //     date: '2017-12-12 18:00',
  //     desc: '大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘'
  //   },
  //   {
  //     title: '对比敏感度与信号研究',
  //     date: '2017-12-12 18:00',
  //     desc: '大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘尽大江东去浪淘'
  //   },
  // ])

  // 公司动态数据
  const newsData = [
    {
      title: 'ARVO 2017 新兴趋势和热点话题丨双眼增强现实范式用于成人弱视治疗',
      route: '/newsInfo/arvo'
    },
    {
      title: '集视医疗获远毅资本天使轮投资，为2700万全年龄段弱视患者提供基于XR技术的视觉训练方法',
      route: '/newsInfo/invest'
    },
    {
      title: '卓悦榜丨集视医疗荣膺“年度智慧医疗最佳企业”奖项',
      route: '/newsInfo/bestEnterprise'
    },
  ]

  const [aniState, setAniState] = useState({
    isSolution1AniEnd: false,
  })
  // // 解决方案 偏移过度
  // const [solution1Ref, solution1ScrollRef] = useWheelTransform({
  //   offset: -600, // 每次滚动的偏移量
  //   initOffset: 0, // 初始偏移量
  //   scrollBar: 'X', // 滚动轴 Y|X
  //   triggerPer: 0.3,
  //   canRuning: aniState.isSolution1AniEnd,
  // })
  // // 解决方案 进入动画
  // useIntoViewAnimation({
  //   ref: solution1Ref,
  //   threshold: 0.1,
  //   className: 'Solution1--ani',
  // })
  // // 解决方案 监听进度动画结束
  // useAnimationAllEnd(solution1Ref, () => {
  //   console.dir('动画结束')
  //   setAniState({
  //     ...aniState,
  //     isSolution1AniEnd: true,
  //   })
  // })
  // 解决方案 进入动画
  const solutionRef = useRef('')
  useIntoViewAnimation({
    ref: solutionRef,
    threshold: 0.1,
    className: 'Solution1--ani',
  })

  // 公司动态 偏移过度
  // const [CompanyNewsRef, newsContentScrollRef] = useWheelTransform({
  //   offset: -20, // 每次滚动的偏移量
  //   initOffset: 0, // 初始偏移量
  //   scrollBar: 'Y', // 滚动轴 Y|X
  //   triggerPer: 1,
  //   canRuning: true,
  // })
  // 公司动态 进入动画
  const CompanyNewsRef = useRef('')
  useIntoViewAnimation({
    ref: CompanyNewsRef,
    threshold: 0.3,
    className: 'Solution1--ani',
  })

  // 关于我们 进入动画
  const aboutUsRef = useRef('')
  useIntoViewAnimation({
    ref: aboutUsRef,
    threshold: 0.3,
    className: 'Solution1--ani',
  })

  // html+css怎样实现固定一个div宽高，里面放img图片原图大于外层div宽高，图片大于div宽高的部分隐藏

  return (
    <div className='App_content' id='app'>
      <div className='App_cover'>
        <div className="App_cover-title">
          世界领先的脑科学机制研究驱动的眼科诊疗平台
        </div>
      </div>
      {/* 以下为解决方案UI重构 */}
      <div className='Solution' ref={solutionRef}>
        <div className='Solution_bgc1'>
          <img
            style={{ width: '100%', height: '100%', }}
            src='./image/bgi1.png'
            alt=''
          />
        </div>
        <span className='Solution_title'>产品及解决方案</span>
        <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
          <div className='Solution_desc'>集视医疗致力于融合底层脑科学+眼病机制研究+可穿戴智能化产品，突破传统干预手段年龄、效果、地点、依从性等限制。</div>
          <Link
            to='/solutionIndex'
            onClick={() => {
              document.scrollingElement.scrollTop = 0
            }}
            className='Solution_btn'
          >
            了解更多
          </Link>
        </div>
        <div className='Solution_content'>
          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <div className='Solution_bgc2'>
              <img
                style={{ width: '100%', height: 'auto', }}
                src='./image/bgi1.png'
                alt=''
              />
            </div>
            <div className='Solution_bgc3'></div>
          </div>
          <div className='Solution_contentBottom'>
            <Link
              className='Solution_contentItem'
              to='/arta'
              onClick={() => {
                document.scrollingElement.scrollTop = 0
              }}
            >
              <div className='Solution_contentItem-bgc' />
              <div style={{ position: 'absolute', zIndex: -1, width: '100%' }}>
                <div className='Solution_ContentImg'>
                  <img
                    style={{ width: '100%', height: 'auto', }}
                    src='./image/arta.png'
                    alt=''
                  />
                </div>
                <div className='Solution_itemTitle'>ARTA 埃尔塔™</div>
                <div className='Solution_itemDesc'>全年龄弱视治疗仪</div>
              </div>
            </Link>
            <Link
              to='/argos'
              onClick={() => {
                document.scrollingElement.scrollTop = 0
              }}
              className='Solution_contentItem'
            >
              <div className='Solution_contentItem-bgc' />
              <div style={{ position: 'absolute', zIndex: -1, width: '100%' }}>
                <div className='Solution_ContentImg'>
                  <img
                    style={{ width: '100%', height: 'auto', }}
                    src='./image/argos.png'
                    alt=''
                  />
                </div>
                <div className='Solution_itemTitle'>ARGOS 爱格视™</div>
                <div className='Solution_itemDesc'>全功能3D近视训练仪</div>
              </div>
            </Link>
            <Link
              to='/eyepal'
              onClick={() => {
                document.scrollingElement.scrollTop = 0
              }}
              className='Solution_contentItem'
            >
              <div className='Solution_contentItem-bgc' />
              <div style={{ position: 'absolute', zIndex: -1, width: '100%' }}>
                <div className='Solution_ContentImg'>
                  <img
                    style={{ width: '100%', height: 'auto', }}
                    src='./image/eyepal.png'
                    alt=''
                  />
                </div>
                <div className='Solution_itemTitle'>EYEPAL 眼派™</div>
                <div className='Solution_itemDesc'>可视温润舒眼仪</div>
              </div>
            </Link>
          </div>
          {/* <div className='Solution_bgc1'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/bgi1.png'
              alt=''
            />
          </div>
          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <div className='Solution_bgc2'>
              <img
                style={{ width: '100%', height: 'auto', }}
                src='./image/bgi2.png'
                alt=''
              />
            </div>
            <div className='Solution_bgc3'></div>
          </div> */}
        </div>
      </div>
      {/* <div className='Solution1' ref={solution1Ref}>
        <div className='Solution1_left'>
          <div style={{ marginLeft: '80px' }}>
            <div className='Solution1_left-title'>产品及解决方案</div>
            <div className='Solution1_left-content'>
              集视医疗致力于融合底层脑科学+眼病机制研究+可穿戴智能化产品，突破传统干预手段年龄、效果、地点、依从性等限制。
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button className='Solution1_left-btn'>了解更多</button>
            </div>
          </div>
        </div>
        <div className='Solution1_right' ref={solution1ScrollRef}>
          <div className="Solution1_right-inner">
            <div style={{ minWidth: '100%' }}>
              <div className='Solution1_right-arta'>
                <div className='Solution1_right-title'>
                  ARTA 埃尔塔™
                  <div>全年龄弱视治疗仪</div>
                </div>
                <img
                  className='Solution1_right-artaImg'
                  src='/image/demo.png'
                  alt=""
                />
              </div>
              <div style={{ display: 'flex', flexFlow: 'row', marginTop: '40px', justifyContent: 'space-between' }}>
                <div className='Solution1_right-agros'>
                  <div className='Solution1_right-title' style={{ maxWidth: 180, flex: 1 }}>
                    AGROS 爱格视™
                    <div>全功能3D近视训练仪</div>
                  </div>
                  <img
                    className='Solution1_right-agrosImg'
                    src='/image/demo.png'
                    alt=""
                  />
                </div>
                <div className='Solution1_right-eyepal'>
                  <div className='Solution1_right-title' style={{ maxWidth: 180, flex: 1 }}>
                    EYPAL眼派™
                    <div>可视温润舒眼仪</div>
                  </div>
                  <img
                    className='Solution1_right-eyepalImg'
                    src='/image/demo.png'
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div style={{ minWidth: 600 }}>
              <div className='Solution1_right-glaucoma'>
                <div className='Solution1_right-title' style={{ maxWidth: 170 }}>
                  创新青光眼治疗方案
                </div>
                <img
                  className='Solution1_right-glaucomaImg'
                  src='/image/demo.png'
                  alt=""
                />
              </div>
              <div className='Solution1_right-glaucoma' style={{ marginTop: 40 }}>
                <div className='Solution1_right-title' style={{ maxWidth: 170 }}>
                  斜视术后康复数字疗法
                </div>
                <img
                  className='Solution1_right-glaucomaImg'
                  src='/image/demo.png'
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className='CompanyNews' ref={CompanyNewsRef}>
        <div className='CompanyNews_title'>公司动态</div>
        <Link
          to='/allNews'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          className='CompanyNews_btn'
        >
          查看全部
        </Link>
        <div className='CompanyNews_content'>
          <div className='CompanyNews_content-left'>
            <div className='CompanyNews_bgi1'></div>
            <div className='CompanyNews_bgi3'>
              <img
                style={{ width: '100%', height: '100%', }}
                src='./image/bgi1.png'
                alt=''
              />
            </div>
            <div className="CompanyNews_content-leftContent">
              <Link to='/newsInfo/academic'
                onClick={() => {
                  document.scrollingElement.scrollTop = 0
                }}
              >
                <div className='CompanyNews_content-leftImg'>
                  <img
                    style={{ width: '100%', height: '100%', borderRadius: 12 }}
                    src='/image/academic(4).png'
                    alt=""
                  />
                </div>
                <div className='CompanyNews_content-leftBtn'>精选文章</div>
                <div className='CompanyNews_content-leftTitle'>集视医疗亮相中华医学会第二十八次眼科学术大会</div>
                <div className='CompanyNews_content-leftDate'>2024.09.05</div>
              </Link>
            </div>
          </div>
          <div className='CompanyNews_content-right' >
            {/* <div className="CompanyNews_content-rightContent" ref={newsContentScrollRef}>
              <div className='CompanyNews_content-rightInner'>
                <img
                  className='CompanyNews_content-rightImg'
                  src='/image/arvo.png'
                  alt=""
                />
                {newsData.map((item, index) => <Link
                  to={item.route}
                  key={index}
                  className="CompanyNews_content-rightItem"
                  onClick={() => {
                    document.scrollingElement.scrollTop = 0
                  }}
                >
                  ·{item.title}
                </Link>)}
              </div>
            </div> */}
            {/* <Link
              to='/allNews'
              onClick={() => {
                document.scrollingElement.scrollTop = 0
              }}
              className='CompanyNews_btn'
            >
              查看全部
            </Link> */}
            <div className="CompanyNews_content-rightImg">
              <img
                style={{ width: '100%', height: '100%' }}
                src='/image/arvo.png'
                alt=""
              />
            </div>
            <div className="CompanyNews_content-rightBottom">
              {newsData.map((item, index) => <Link
                to={item.route}
                key={index}
                className="CompanyNews_content-rightItem"
                onClick={() => {
                  document.scrollingElement.scrollTop = 0
                }}
              >
                · {item.title}
              </Link>)}
            </div>
          </div>
          <div className='CompanyNews_bgi2'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/bgi1.png'
              alt=''
            />
          </div>
        </div>
      </div>
      {/* <div className='FCST'>
        <div className='FCST_left'>
          <img className='Rectangle_grey' src='/image/Rectangle-grey.png' alt='' />
          <img className='Rectangle_red' src='/image/Rectangle-red.png' alt='' />
          <img className='big_image' src='/image/FCST_image.png' alt='' />
          <img className='greenIcon' src='/image/FCST.png' alt='' />
        </div>
        <div className='FCST_right'>
          <div className='FCST_right-top'>FCST研究院</div>
          <div className="FCST_banner">
            <div
              ref={bannerContentRef}
              className={`FCST_bannerContent ${trsFlag ? 'FCST_bannerContent--trs' : ''}`}
              style={{
                transform: `translateX(-${index + 1}00%)`,
              }}
            >
              <Card d={data[data.length - 1]} />
              {data.map((d) => <Card d={d} key={d.title} />)}
              <Card d={data[0]} />
            </div>
          </div>
          <div className='FCST_right-bottom'>
            <img
              src='/image/arrow-left-selected.png'
              alt=''
              className='FCST_right-arrow'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setTrsFlag(true)
                setIndex(index - 1)
                if (index <= 0) {
                  setTimeout(() => {
                    setTrsFlag(false)
                    setIndex(data.length - 1)
                  }, 300)
                }
              }}
            />
            <img
              src='/image/arrow-right-selected.png'
              alt=''
              className='FCST_right-arrow'
              style={{ marginLeft: '10px', cursor: 'pointer' }}
              onClick={() => {
                setTrsFlag(true)
                setIndex(index + 1)
                if (index >= data.length - 1) {
                  setTimeout(() => {
                    setTrsFlag(false)
                    setIndex(0)
                  }, 300)
                }
              }}
            />
          </div>
        </div>
      </div> */}
      <div className='AboutUs' ref={aboutUsRef}>
        <div className='AboutUs_content'>
          <div className='AboutUs_contentLeft'>
            <img
              style={{ height: '100%' }}
              src='./image/aboutUs.png'
              alt=''
            />
          </div>
          <div className="AboutUs_contentRight">
            <div className='AboutUs_rightTitle'>关于我们</div>
            <div className='AboutUs_rightDesc'>
              集视医疗是一家专注于眼科医疗器械的创新型企业。
            </div>
            <Link
              to='/companyProfile'
              onClick={() => {
                document.scrollingElement.scrollTop = 0
              }}
              className='AboutUs_btn'
            >
              了解更多
            </Link>
          </div>
        </div>
        <div className="AboutUs_bgi1"></div>
        <div className="AboutUs_bgi2"></div>
        <div className="AboutUs_bgi3"></div>
        <div className="AboutUs_bgi4"></div>
      </div>
    </div >
  );
}

export default Index;


const Card = ({ d }) => <div className='FCST_right-item'>
  <img className='FCST_mark' src='/image/FCST_mark.png' alt="" />
  <div>
    <div className='FCST_rightItem-title'>{d.title}</div>
    <div className='FCST_rightItem-date'>{d.date}</div>
    <div className='FCST_rightItem-desc'>{d.desc}</div>
  </div>
</div>