import './literature.less';
import { Link, useNavigate } from "react-router-dom";

export default function Literature() {
  const navigate = useNavigate();
  const newsData = [
    {
      title: '理论突破 - 弱视的皮层下受损',
      date: '2024-10-31',
      desc: '弱视患者的一个重要临床表现在于弱视眼对空间细节的敏感度降低，包括视锐度（眼睛辨别最小视角的能力）降低；对比度敏感度，尤其是对高空间频率（细密纹理）的对比度敏感度降低…',
      img: '/image/literature1.png',
      route: '/fcst/literatureinfo/breakthroughTheory'
    },
    {
      title: '方法突破 - 结合增强现实的，可以针对弱视眼P通路进行矫正的双眼图像算法',
      date: '2024-10-11',
      desc: 'Xu等人在2010年证明了通过使用推拉（Push-pull）方法来减少眼优势（sensory eye dominance）的可能性。在训练过程中（图 C），受试者的任务是辨别非优势眼看到中的光栅朝向…',
      img: '/image/literature2.png',
      route: '/fcst/literatureinfo/breakthroughMethod'
    },
  ]
  return <div className="Literature">
    <div className='Literature_bgc1'></div>
    <div className='Literature_bgc2'></div>
    <div className='Literature_bgc3'></div>
    <div className='Literature_bgc4'></div>
    <div className='Literature_bgc5'></div>
    <div className="Literature_top">
      <div
        className="Literature_top-img"
        // to='/fcst'
        onClick={() => {
          navigate(-1)
          // document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          style={{ width: '100%', height: '100%' }}
          src='/image/leftArrow(1).png'
          alt=''
        />
      </div>
      <div className="Literature_title">文献速递</div>
    </div>
    <div className="Literature_content">
      {newsData.map((item, index) => <Link
        key={index}
        to={item.route}
        className="Literature_contentItem"
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <div className='Literature_contentItem-img'>
          <img
            style={{ width: '100%', height: '100%'}}
            src={item.img}
            alt=''
          />
        </div>
        <div className='Literature_contentItem-right'>
          <div className='Literature_contentItem-rightTitle'>{item.title}</div>
          <div className='Literature_contentItem-rightDate'>{item.date}</div>
          <div className='Literature_contentItem-rightDesc'>{item.desc}</div>
        </div>
      </Link>)}
    </div>
  </div>
}
