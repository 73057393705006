import { Link } from 'react-router-dom';
import './literatureInfo.less';

export default function BreakthroughTheory() {
  return <div className="LiteratureInfo">
    <div className='LiteratureInfo_bgc1'></div>
    <div className='LiteratureInfo_bgc2'></div>
    <div className='LiteratureInfo_bgc3'></div>
    <div className='LiteratureInfo_bgc4'></div>
    <div className='LiteratureInfo_bgc5'></div>
    <div className="LiteratureInfo_content">
      <Link
        to='/fcst/literature'
        className="LiteratureInfo_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有文献
      </Link>
      <div className="LiteratureInfo_content-title">理论突破 - 弱视的皮层下受损</div>
      <div className="LiteratureInfo_content-date">2024-10-31</div>
      <div className="LiteratureInfo_content-line"></div>
      <div className="LiteratureInfo_content-desc">
        弱视患者的一个重要临床表现在于弱视眼对空间细节的敏感度降低，包括视锐度（眼睛辨别最小视角的能力）降低；对比度敏感度，尤其是对高空间频率（细密纹理）的对比度敏感度降低。<br />
        而既往的神经生物学发现，人脑在处理空间信息时可以进一步分为两条通路，各种对不同的空间特征表现出了不同的神经响应（ref）。其中小细胞（Parvocellular, P）通路被发现是一条主要处理高空间频率信息的通路，同时它还对低时间频率更加敏感，这一特征表明其更善于处理空间中的细节信息，代表视觉信息处理中的慢过程。
      </div>
      <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div className="LiteratureInfo_content-img">
          <img
            style={{ width: '100%', height: '100%' }}
            src='/image/breakthroughTheory1.png'
            alt=""
          />
        </div>
      </div>
      <div className="LiteratureInfo_content-desc">
        相对应的，M通路（Magnocellular, M）被发现是一条主要处理低空间频率信息的通路，同时它还对高时间频率更加敏感，表明其更善于处理空间中的粗略的运动信息，代表视觉信息处理中的快过程。
        如果弱视患者的重要临床表现在于弱视眼对空间细节的敏感度降低，那么其更善于处理空间中的细节信息的P通路是否也会表现出受损呢？长期以来，由于M/P通路的分层发生在人脑皮层下的核心深处（主要表现在外膝体上），
        常规的脑成像方法很难获得清晰的成像，使得上述假设缺乏科学的证据证明。近期，中科院生物物理生所与上海复旦眼耳鼻喉科医院依托先进的高场磁共振技术，对弱视患者的皮层下核团进行了研究（ref）。
        高场磁共振是一种在核磁共振成像过程中采用更高磁场的技术，常规医院检查与研究中，常见的成像磁场为1.5 Tesla与3 Tesla，而高场磁共振则使用了7 Tesla进行成像。通过该方法，研究者可以获得更为清晰的皮层下成像，
        从而进一步探究弱视患者的皮层下通路与正常被试的异同。结果发现，当弱视眼呈现高空间频率刺激时，外膝体小细胞层的响应相对于大细胞层同样出现了更大程度的下降。同时对皮层与皮层下核团的功能连接分析结果也显示，
        弱视眼从外膝体P层到皮层V1的前馈连接减弱。这些结果都表明弱视患者弱视眼在小细胞通路存在功能上的损伤。这一结果帮助我们更精准的定位了弱视患者视觉系统中异常的病灶所在，为后期开发出一套更针对，更有效的弱视矫正方法提供了重要的理论基础。
      </div>
      <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div className="LiteratureInfo_content-img">
          <img
            style={{ width: '100%', height: '100%' }}
            src='/image/breakthroughTheory2.png'
            alt=""
          />
        </div>
      </div>
      <div className="LiteratureInfo_content-subTitle">点击获取文献原文</div>
      <div className='LiteratureInfo_content-link'>
        <a
          href="https://www.cell.com/cell-reports/fulltext/S2211-1247(21)01611-9"
          target='_'
        >
          https://www.cell.com/cell-reports/fulltext/S2211-1247(21)01611-9
        </a>
      </div>
      <div className="LiteratureInfo_content-line"></div>
    </div>
  </div>
}
