import './sciencePopularization.less';
import { Link, useNavigate } from "react-router-dom";

export default function SciencePopularization() {
  const navigate = useNavigate();
  const newsData = [
    {
      title: '什么是弱视？',
      date: '2024-10-11',
      desc: '弱视是一种视觉发育相关疾病，其特征为戴镜视力达不到同龄儿童正常视力下线或者两眼视力相差2行以上，且经眼科检查并未发现能够引起视力差的器质性病变…',
      img: '/image/sciencePopularization2.png',
      route: '/fcst/SciencePopularizationInfo/identifyAmblyopia'
    },
    {
      title: '近视防控应该怎么做？',
      date: '2024-10-11',
      desc: <>
        首先判断是真性近视还是假性近视。<br />
        如果孩子出现"假性近视”，可以让孩子眼睛彻底放松，使眼睛慢慢恢复…
      </>,
      img: '/image/sciencePopularization1.png',
      route: '/fcst/SciencePopularizationInfo/identifyMyopia'
    },
  ]
  return <div className="SciencePopularization">
    <div className='SciencePopularization_bgc1'></div>
    <div className='SciencePopularization_bgc2'></div>
    <div className='SciencePopularization_bgc3'></div>
    <div className='SciencePopularization_bgc4'></div>
    <div className='SciencePopularization_bgc5'></div>
    <div className="SciencePopularization_top">
      <div
        className="SciencePopularization_top-img"
        // to='/fcst'
        onClick={() => {
          navigate(-1)
          // document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          style={{ width: '100%', height: '100%' }}
          src='/image/leftArrow(1).png'
          alt=''
        />
      </div>
      <div className="SciencePopularization_title">科普专栏</div>
    </div>
    <div className="SciencePopularization_content">
      {newsData.map((item, index) => <Link
        key={index}
        to={item.route}
        className="SciencePopularization_contentItem"
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <div className='SciencePopularization_contentItem-img'>
          <img
            style={{ width: '100%', height: '100%' }}
            src={item.img}
            alt=''
          />
        </div>
        <div className='SciencePopularization_contentItem-right'>
          <div className='SciencePopularization_contentItem-rightTitle'>{item.title}</div>
          <div className='SciencePopularization_contentItem-rightDate'>{item.date}</div>
          <div className='SciencePopularization_contentItem-rightDesc'>{item.desc}</div>
        </div>
      </Link>)}
    </div>
  </div>
}
