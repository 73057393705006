import { Link } from 'react-router-dom';
import './amblyopiaClinical.less';

export default function AmblyopiaClinical() {
  return <div className="AmblyopiaClinical">
    <div className='AmblyopiaClinical_bgc1'></div>
    <div className='AmblyopiaClinical_bgc2'></div>
    <div className='AmblyopiaClinical_bgc3'></div>
    <div className='AmblyopiaClinical_bgc4'></div>
    <div className='AmblyopiaClinical_bgc5'></div>
    <div className="AmblyopiaClinical_content">
      <Link
        to='/allNews'
        className="AmblyopiaClinical_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有动态
      </Link>
      <div className="AmblyopiaClinical_content-title">集视医疗弱视方案临床试验初步访视结果出炉，001号患者4周基本治愈！</div>
      <div className="AmblyopiaClinical_content-date">2024-10-11</div>
      <div className="AmblyopiaClinical_content-line"></div>
      <div className="AmblyopiaClinical_content-descTop">
        苏州集视医疗科技有限公司（以下简称“集视医疗”）于国内由复旦大学附属眼耳鼻喉科医院牵头，联合多中心开展的全球首个“基于AR技术的全年龄段弱视治疗方案的有效性和安全性”的前瞻性、
        随机对照临床试验，同时也是全世界首张优效于治疗金标准的弱视治疗方法注册，成功打破了“大龄弱视不可治”这一传统认知壁垒，将弱视的临床治疗年龄从12周岁拓展至55周岁。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='AmblyopiaClinical_content-img1'
          src='/image/amblyopiaClinical1.png'
          alt=""
        />
      </div>
      <div className="AmblyopiaClinical_content-descTop">
        近日该临床试验001号患者已完成初步访视，结果显示该患者（25岁，男性），基线BCVA（最佳矫正视力）为0.7logmar（小数视力0.2），治疗两周后BCVA提高至0.2ogmar（小数视力0.64）；继续治疗后BCVA持续提高，第四周BCVA提高到0.12ogmar，基本接近弱视治愈标准。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='AmblyopiaClinical_content-img2'
          src='/image/amblyopiaClinical2.png'
          alt=""
        />
      </div>
      <div className="AmblyopiaClinical_content-descTop">
        集视医疗正在加速推进全年龄弱视治疗方案的临床试验，致力于让该方案尽快惠及更多弱视患者！
      </div>
      <div className="AmblyopiaClinical_content-line"></div>
    </div>
  </div>
}
