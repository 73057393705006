import './Footer.less'
import { Link, } from "react-router-dom";

export default function footer() {
  const scrollTop = () => {
    document.scrollingElement.scrollTop = 0
  }
  return (
    <div className="App_footer">
      <div className="App_footer-top">
        <div className="App_footer-topLeft">
          <img
            style={{ width: '100%', height: '100%' }}
            src='/image/footer.png'
          />
        </div>
        <div className='App_footer-topRight'>
          <div className='App_footer-topRightItem'>
            <div className="App_footer-title">公司</div>
            <Link to='/companyProfile'
              className="App_footer-subitem"
              onClick={scrollTop}
            >
              公司介绍
            </Link>
            <Link to='/companyProfile'
              className="App_footer-subitem"
              onClick={scrollTop}
            >
              发展历程
            </Link>
          </div>
          <div className='App_footer-topRightItem'>
            <div className="App_footer-title">产品</div>
            <Link to='/arta'
              className="App_footer-subitem"
              onClick={scrollTop}
            >
              ARTA™全年龄弱视治疗仪
            </Link>
            <Link to='/argos'
              className="App_footer-subitem"
              onClick={scrollTop}
            >
              ARGOS™全功能3D近视训练仪
            </Link>
            <Link to='/eyepal'
              className="App_footer-subitem"
              onClick={scrollTop}
            >
              EYEPAL™可视温润舒眼仪
            </Link>
          </div>
          <div className='App_footer-topRightItem'>
            <div className="App_footer-title">动态</div>
            <Link to='/companyNews'
              className="App_footer-subitem"
              onClick={scrollTop}
            >
              新闻资讯
            </Link>
            <Link to='/fcst'
              className="App_footer-subitem"
              onClick={scrollTop}
            >
              文献速递
            </Link>
          </div>
          <div className='App_footer-topRightItem'>
            <div className="App_footer-title">联系</div>
            <Link to='/contactUs'
              className="App_footer-subitem"
              onClick={scrollTop}
            >
              联系我们
            </Link>
          </div>
        </div>
      </div>
      <div className="App_footer-line"></div>
      <div className="App_footer-bottom">
        <div>Copyright©2022.杭州集视智能科技有限公司 All rights reserved.</div>
        <a
          href="https://beian.miit.gov.cn"
          target='_blank'
          style={{ color: '#ffffff', textDecoration: 'none' }}
        >
          浙ICP备2022037241号-5
        </a>
      </div>
      <div className="App_footer-bgi1"></div>
      <div className="App_footer-bgi2"></div>
      <div className="App_footer-bgi3"></div>
      <div className="App_footer-bgi4"></div>
    </div>
  );
}
