import { Link } from 'react-router-dom';
import './contract.less';

export default function Contract() {
  return <div className="Contract">
    <div className='Contract_bgc1'></div>
    <div className='Contract_bgc2'></div>
    <div className='Contract_bgc3'></div>
    <div className='Contract_bgc4'></div>
    <div className='Contract_bgc5'></div>
    <div className="Contract_content">
      <Link
        to='/allNews'
        className="Contract_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有动态
      </Link>
      <div className="Contract_content-title">温州医科大学附属眼视光医院＆集视医疗战略合作签约</div>
      <div className="Contract_content-date">2024-10-31</div>
      <div className="Contract_content-line"></div>
      <div className="Contract_content-descTop">
        2024年10月，集视医疗与温州医科大学附属眼视光医院达成了一项具有里程碑意义的战略合作协议。根据协议，集视医疗的视功能训练产品将正式入驻温州医科大学附属眼视光医院的视功能训练室，
        由视光诊疗中心负责人郑医生牵头，为患者带来更全面、更科学的视功能训练服务。
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <img
          className='Contract_content-img'
          src='/image/contractImg1.png'
          alt=""
        />
      </div>
      <div className="Contract_content-descTop">
        集视医疗是由全球领先的脑科学、眼科临床、可穿戴技术深度融合的产学研团队组成，结合脑科学深入研究眼科相关疾病的发病机制并设计针对性诊疗范式，
        开发基于可穿戴设备、符合临床路径、个性化、高依从性的诊疗产品。从成人弱视切入，未来拓展到低视力、青光眼、干眼等全方位眼健康数字化管理，全面提升眼科治疗的整体水平，
        改善患者的生活质量，弥补当前眼科治疗的短板。
      </div>
      <div className="Contract_content-line"></div>
    </div>
  </div>
}
