import './arta.less';

export default function Arta() {
  return <div className="Arta">
    <div className='Arta_cover'>
      <div className="Arta_cover-title">
        ARTA埃尔塔™
      </div>
    </div>
    <div className="Arta_contentArta">
      {/* <div className='Arta_contentArta-top'> */}
      <div className='Arta_contentArta-title'>ARTA 埃尔塔™</div>
      <div className='Arta_contentArta-subTitle'>全年龄弱视治疗仪</div>
      {/* </div> */}
      <div className='Arta_contentArta-bottom'>
        <div className='Arta_contentArta-bottomBgi'>
          <img
            style={{ width: '100%', height: '100%', }}
            src='./image/bgi1.png'
            alt=''
          />
        </div>
        <div className='Arta_contentArta-bottomBg2'></div>
        <div className='Arta_contentArta-bottomGroup'>
          <div className='Arta_contentArta-bottomItem'>
            <div className='Arta_contentArta-bottomIcon'>
              <img
                style={{ width: '100%', height: '100%' }}
                src='./image/artaIcon(1).png'
                alt=''
              />
            </div>
            <div className='Arta_contentArta-bottomTitle'>机制</div>
            <div className='Arta_contentArta-bottomDesc'>
              集视牵手中科院<br />揭示弱视病理机制
            </div>
          </div>
          <div className='Arta_contentArta-bottomItem'>
            <div className='Arta_contentArta-bottomIcon'>
              <img
                style={{ width: '100%', height: '100%' }}
                src='./image/artaIcon(2).png'
                alt=''
              />
            </div>
            <div className='Arta_contentArta-bottomTitle'>创新</div>
            <div className='Arta_contentArta-bottomDesc'>
              全新的“双眼、双通路”<br />推拉治疗方案
            </div>
          </div>
          <div className='Arta_contentArta-bottomItem'>
            <div className='Arta_contentArta-bottomIcon'>
              <img
                style={{ width: '100%', height: '100%' }}
                src='./image/artaIcon(3).png'
                alt=''
              />
            </div>
            <div className='Arta_contentArta-bottomTitle'>疗效</div>
            <div className='Arta_contentArta-bottomDesc'>
              临床验证<br />疗效优势明显
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='Arta_contentCell'>
      <div className='Arta_contentCell-left'>
        <div className='Arta_contentCell-leftBgi1'>
          <img
            style={{ width: '100%', height: 'auto', }}
            src='./image/bgi1.png'
            alt=''
          />
        </div>
        <div className='Arta_contentCell-leftBgi3'></div>
        <div className='Arta_contentCell-leftBgi2'></div>
        <div className='Arta_contentCell-leftBgi4'>
          <img
            style={{ width: '100%', height: 'auto', }}
            src='./image/bgi2.png'
            alt=''
          />
        </div>
        <div className='Arta_contentCell-leftImg'>
          <img
            style={{ width: '100%', height: 'auto', borderRadius: 12 }}
            src='./image/cell(1).png'
            alt=''
          />
        </div>
      </div>
      <div className='Arta_contentCell-right'>
        <div className='Arta_contentCell-rightTitle'>
          集视牵手中科院，<br />
          揭示弱视病理机制
          {/* <div style={{ display: 'flex', flexFlow: 'row' }}>
            <div>集视牵手</div>
            <div className='Arta_contentCell-rightTitleTop'>中科院，</div>
          </div>
          揭示弱视病理机制 */}
        </div>
        <div className='Arta_contentCell-rightContent'>
          传统观念认为弱视主要影响人类早期视觉皮层；<br />
          集视团队最新的研究揭示成人弱视外膝体-V1小细胞通路和皮层-上丘通路的功能异常；<br />
          针对弱视神经损伤位点的训练有望解决传统疗法年龄限制、依从性差、效果不佳、回退率高等问题。
        </div>
        <div className='Arta_contentCell-rightImg'>
          <img
            style={{ width: '100%', height: 'auto', borderRadius: 12 }}
            src='./image/cell(2).png'
            alt=''
          />
        </div>
      </div>
    </div>
    <div className='Arta_principles'>
      <div className='Arta_principles-bgi1'></div>
      <div className="Arta_principles-content">
        <div className="Arta_principles-left">
          <div className="Arta_principles-leftTitle">全新的技术原理</div>
          <div className="Arta_principles-leftDesc">独创的双眼推拉训练方案，重建双眼信息感知能力</div>
          <div className="Arta_principles-leftContent mt_20">
            “双眼”推拉训练<br />
            AE呈现正常信号<br />
            FE呈现干扰信号<br />
            提高来自AE的信息比重，降低眼间抑制
          </div>
          <div className="Arta_principles-leftContent">
            “双通路”推拉训练<br />
            P通路呈现正常信号<br />
            M通路呈现干扰信号<br />
            提高来自P通路的信息比重，改善单眼功能
          </div>
        </div>
        <div className="Arta_principles-right">
          <div className='Arta_principles-rightBgi1'>
            <img
              style={{ width: '100%', height: '100%', }}
              src='./image/bgi1.png'
              alt=''
            />
          </div>
          <div className='Arta_principles-rightBgi2'>
            <img
              style={{ width: '100%', height: '100%', }}
              src='./image/bgi1.png'
              alt=''
            />
          </div>
          <div className='Arta_principles-rightImg'>
            <img
              style={{ width: '100%', height: '100%', }}
              src='./image/principlesBgi1.png'
              alt=''
            />
          </div>
        </div>
      </div>
      {/* <div className='Arta_principles-bgi'>
        <div className='Arta_principles-img'>
          <img
            style={{ width: '100%', height: '100%', }}
            src='./image/principlesBgi1.png'
            alt=''
          />
        </div>
        <div className='Arta_principles-bgi3'>
          <img
            style={{ width: '100%', height: '100%', }}
            src='./image/bgi1.png'
            alt=''
          />
        </div>
        <div className='Arta_principles-bgi4'>
          <img
            style={{ width: '100%', height: '100%', }}
            src='./image/bgi1.png'
            alt=''
          />
        </div>
      </div>
      <div className='Arta_principles-bgi2'></div> */}
      {/* <div className='Arta_principles-top'>
        <span className='Arta_principles-title'>
          全新的技术原理
        </span>
        <div className='Arta_principles-subTitle'>独创的双眼推拉训练方案，重建双眼信息感知能力</div>
      </div> */}
      {/* <div className='Arta_principles-content'>
        <div style={{ display: 'flex', zIndex: 10 }}>
          <div className='Arta_principles-contentItem'>
            “双眼”推拉训练<br />
            AE呈现正常信号<br />
            FE呈现干扰信号<br />
            提高来自AE的信息比重，降低眼间抑制
          </div>
          <div className='Arta_principles-contentItem'>
            ”双通路“推拉训练<br />
            P通路呈现正常信号<br />
            M通路呈现干扰信号<br />
            提高来自P通路的信息比重，改善单眼功能
          </div>
        </div>
      </div> */}
    </div>
    <div className='Arta_clinical'>
      <div className='Arta_clinical-top'>
        <div className='Arta_clinical-topLeft'>
          <div className='Arta_clinical-Bgi1'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/bgi1.png'
              alt=''
            />
          </div>
          <div className='Arta_clinical-Bgi2'></div>
          <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>
            <div className='Arta_clinical-leftImg'>
              <div className='Arta_clinical-leftImgBgc'></div>
              <img
                style={{ width: '100%', height: '100%', borderRadius: 12 }}
                src='/image/chart.png'
                alt=''
              />
            </div>
            <div className='Arta_clinical-leftLegend'>
              <div className="Arta_clinical-leftLegendBgc" style={{ opacity: 0.08 }}></div>
              <div className="Arta_clinical-legendItem">
                <div className="Arta_clinical-legendItemLeft" style={{ backgroundColor: '#B0A2F6' }}></div>
                <div className="Arta_clinical-legendItemRight">单眼遮盖</div>
              </div>
              <div className="Arta_clinical-legendItem">
                <div className="Arta_clinical-legendItemLeft" style={{ backgroundColor: '#EFD2C5' }}></div>
                <div className="Arta_clinical-legendItemRight">LUMINOPIA</div>
              </div>
              <div className="Arta_clinical-legendItem">
                <div className="Arta_clinical-legendItemLeft" style={{ backgroundColor: '#ECC3D3' }}></div>
                <div className="Arta_clinical-legendItemRight">NOVASIGHT</div>
              </div>
              <div className="Arta_clinical-legendItem">
                <div className="Arta_clinical-legendItemLeft" style={{ backgroundColor: '#ABD1ED' }}></div>
                <div className="Arta_clinical-legendItemRight" style={{ color: '#FC5355' }}>集视方案</div>
              </div>
            </div>
          </div>
          <div className='Arta_clinical-leftTip'>
            <div>注：</div>
            <div>
              LUMINOPIA是FDA批准的首款弱视数字治疗产品<br />
              NovaSight是一家以色列公司，其弱视数字治疗产品经过了严格的RCT验证
            </div>
          </div>
        </div>
        <div className='Arta_clinical-topRight'>
          临床验证<br />
          疗效优势明显
          {/* <div className='Arta_clinical-topRightBottom'>疗效优势明显</div> */}
        </div>
      </div>
      <div className='Arta_clinical-bottom'>
        <div className='Arta_clinical-bottomItem'>
          <div className='Arta_clinical-itemIcon'>
            <img
              style={{ width: '100%', height: '100%', }}
              src='./image/artaIcon(4).png'
              alt=''
            />
          </div>
          <div className='Arta_clinical-itemContent'>
            <div className='Arta_clinical-itemTitle'>唯一</div>
            <div className='Arta_clinical-itemDesc'>
              唯一一款全年龄段优效于金标准疗法有效率的治疗产品
            </div>
          </div>
        </div>
        <div className='Arta_clinical-bottomItem'>
          <div className='Arta_clinical-itemIcon'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/artaIcon(5).png'
              alt=''
            />
          </div>
          <div className='Arta_clinical-itemContent'>
            <div className='Arta_clinical-itemTitle'>突破</div>
            <div className='Arta_clinical-itemDesc'>
              弱视治疗年龄范围拓宽至55周岁
            </div>
          </div>
        </div>
        <div className='Arta_clinical-bottomItem'>
          <div className='Arta_clinical-itemIcon'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/artaIcon(6).png'
              alt=''
            />
          </div>
          <div className='Arta_clinical-itemContent'>
            <div className='Arta_clinical-itemTitle'>提升</div>
            <div className='Arta_clinical-itemDesc'>
              提升矫正视力，显著改善拥挤效应；提升高阶视功能
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='Arta_train'>
      <div className='Arta_train-content'>
        <div className='Arta_train-contentLeft'>
          <img
            // style={{ width: '100%', height: 'auto', }}
            src='./image/arta(2).png'
            alt=''
          />
        </div>
        <div className="Arta_train-contentRight">
          <div className='Arta_train-rightTitle'>工作生活，随时训练</div>
          <div className='Arta_train-rightBottom'>
            <div className='Arta_train-rightItem'>
              <img
                className="Arta_train-rightIcon"
                src='./image/icon(3).png'
              />
              <div className='Arta_train-rightDesc'>首创增强现实技术，环境交互性强</div>
            </div>
            <div className='Arta_train-rightItem'>
              <img
                className="Arta_train-rightIcon"
                src='./image/icon(3).png'
              />
              <div className='Arta_train-rightDesc'>患者自主选择训练画面，依从性高</div>
            </div>
            <div className='Arta_train-rightItem'>
              <img
                className="Arta_train-rightIcon"
                src='./image/icon(3).png'
              />
              <div className='Arta_train-rightDesc'>支持手机、平板等多种设备作为信息源，更具便利性</div>
            </div>
          </div>
        </div>
        <div className="Arta_train-bgi1"></div>
        <div className="Arta_train-bgi2"></div>
        <div className="Arta_train-bgi3"></div>
        <div className="Arta_train-bgi4"></div>
      </div>
    </div>
  </div >
}
