import { useEffect } from "react";

export default function useIntoViewAnimation({
  ref,
  threshold = 0,
  className = '',
}) {

  useEffect(() => {
    const dom = ref.current
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // 元素进入视口
          console.dir(123)
          dom.classList.add(className)
          observer.unobserve(dom)
        }
      });
    }, {
      threshold,
    });
    observer.observe(dom);
    return () => observer.unobserve(dom)
  }, [])

}