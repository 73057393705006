import { Link } from 'react-router-dom';
import './sciencePopularizationInfo.less';

export default function ChooseMyopiaProduct() {
  return <div className="SciencePopularizationInfo">
    <div className='SciencePopularizationInfo_bgc1'></div>
    <div className='SciencePopularizationInfo_bgc2'></div>
    <div className='SciencePopularizationInfo_bgc3'></div>
    <div className='SciencePopularizationInfo_bgc4'></div>
    <div className='SciencePopularizationInfo_bgc5'></div>
    <div className="SciencePopularizationInfo_content">
      <Link
        to='/fcst/sciencePopularization'
        className="SciencePopularizationInfo_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有科普文章
      </Link>
      <div className="SciencePopularizationInfo_content-title">【科普】近视防控产品应该怎么选择</div>
      <div className="SciencePopularizationInfo_content-date">2024-10-31</div>
      <div className="SciencePopularizationInfo_content-line"></div>
      <div className="SciencePopularizationInfo_content-descTop">
        严格意义上来说，近视防控应该分为两类，一个是“防”——阻止近视发生，另一个是“控”——控制近视程度增长，但是目前市场上主流的方案都是用于近视度数增长的控制。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="SciencePopularizationInfo_content-img">
          <img
            style={{ width: '100%', height: '100%', borderRadius: 12 }}
            src='/image/FCST(4).png'
            alt=""
          />
        </div>
      </div>
      <div className="SciencePopularizationInfo_content-descTop">
        目前比较常见的OK镜和阿托品都需要在专业的眼科医生的指导下进行使用，均能在一定程度上控制眼轴的症状，延缓近视度数的加深，但是对已经近视的患者并不能起到“摘镜”的作用；<br /><br />
        年龄较小的近视患者切记盲目进行近视手术，因为未成年人眼部发育未完善，眼球仍处于生长发育阶段，且近视度数不稳定，若盲目接受激光近视手术，首先降低了激光近视手术的安全性，其次术后易出现视力回退、反弹等并发症。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="SciencePopularizationInfo_content-img">
          <img
            style={{ width: '100%', height: '100%', borderRadius: 12 }}
            src='/image/FCST(6).png'
            alt=""
          />
        </div>
      </div>
      <div className="SciencePopularizationInfo_content-descTop">
        爱格视™是一款融合三大功能，眼球+脑视觉双通路防控近视，更全面的近视防控产品。<br />
        其功能包括：<br />
        光学远像技术，能够实现看近等同于看远，有效放松睫状肌，缓解视疲劳；<br />
        3D立体视训练，通过游戏化的方式训练，有效提升孩子调节功能；<br />
        脑视觉锐化训练，是目前唯一一款用于近视患者的脑视觉训练方案，可直接提升裸眼/矫正视力。
      </div>
      <div className="SciencePopularizationInfo_content-line"></div>
    </div>
  </div>
}
