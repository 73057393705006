import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import './Header2.less'

export const solutionData = [
  {
    name: 'ARTA 埃尔塔™ 全年龄弱视治疗仪',
    router: 'arta',
  },
  {
    name: 'ARGOS 爱格视™ 全功能3D近视训练仪',
    router: 'argos',
  },
  {
    name: 'EYEPAL 眼派™ 可视温润舒眼仪',
    router: 'eyepal',
  },
  {
    name: '创新青光眼治疗方案',
    router: 'glaucoma',
  },
  {
    name: '斜视术后康复数字疗法',
    router: 'strabismus',
  },
]

export default function Header() {
  const location = useLocation();
  // console.log('location.pathname', location.pathname)
  const getHeaderMenuTitleClass = () => {
    switch (location.pathname) {
      // case '/contactUs':
      // case '/companyProfile':
      case '/allNews':
      case '/newsInfo/contract':
      case '/newsInfo/arvo':
      case '/newsInfo/pre':
      case '/newsInfo/amblyopiaClinical':
      case '/newsInfo/invest':
      case '/newsInfo/bestEnterprise':
      case '/newsInfo/academic':
      case '/fcst/literature':
      case '/fcst/sciencePopularization':
      case '/fcst/literatureinfo/breakthroughTheory':
      case '/fcst/literatureinfo/breakthroughMethod':
      case '/fcst/SciencePopularizationInfo/identifyAmblyopia':
      case '/fcst/SciencePopularizationInfo/identifyMyopia':
      case '/fcst/SciencePopularizationInfo/treatAmblyopia':
      case '/fcst/SciencePopularizationInfo/chooseMyopiaProduct':
        return 'Header_menu-title Header_menu-titleColor';
      default:
        return 'Header_menu-title';
    }
  };
  const getLogoImg = () => {
    switch (location.pathname) {
      // case '/contactUs':
      // case '/companyProfile':
      case '/allNews':
      case '/newsInfo/contract':
      case '/newsInfo/arvo':
      case '/newsInfo/pre':
      case '/newsInfo/amblyopiaClinical':
      case '/newsInfo/invest':
      case '/newsInfo/bestEnterprise':
      case '/newsInfo/academic':
      case '/fcst/literature':
      case '/fcst/sciencePopularization':
      case '/fcst/literatureinfo/breakthroughTheory':
      case '/fcst/literatureinfo/breakthroughMethod':
      case '/fcst/SciencePopularizationInfo/identifyAmblyopia':
      case '/fcst/SciencePopularizationInfo/identifyMyopia':
      case '/fcst/SciencePopularizationInfo/treatAmblyopia':
      case '/fcst/SciencePopularizationInfo/chooseMyopiaProduct':
        return '/image/focusight(1).png';
      default:
        return '/image/logo.png';
    }
  };
  console.log('location.pathname', location.pathname)

  const FcstDate = [
    {
      name: '文献速递',
      router: 'fcst/literature',
    },
    {
      name: '科普专栏',
      router: 'fcst/sciencePopularization',
    },
  ]

  const [key, setKey] = useState(Date.now());
  useEffect(() => { // 当路径变化时执行刷新操作
    setKey(Date.now());
  }, [location.pathname]);

  const [isVisible, setIsVisible] = useState(false);

  // 页面滚动
  const handleScroll = (event) => {
    // console.log('event', event.deltaY)
    // console.log('window.scrollY', window.scrollY)
    // if (event.deltaY > 0 && window.scrollY >= 150) {
    //   console.dir('向下滚动向下滚动向下滚动')
    //   setIsHeaderUpVisible(false);
    //   setIsHeaderDownVisible(false);
    // }
    // else {
    //   if (window.scrollY < 150) {
    //     console.dir('向上滚动向上滚动向上滚动, 并且小于150')
    //     setIsHeaderUpVisible(true);
    //     setIsHeaderDownVisible(true);
    //   } else {
    //     console.dir('向上滚动向上滚动向上滚动')
    //     setIsHeaderDownVisible(true);
    //   }
    // }
    if (window.scrollY < 150) {
      // setIsHeaderUpVisible(true);
      // setIsHeaderDownVisible(true);
    } else if (event.deltaY < 0) {
      // setIsHeaderDownVisible(true);
      // console.dir('向上滚动向上滚动向上滚动')
    } else if (event.deltaY >= 0) {
      // setIsHeaderUpVisible(false);
      // setIsHeaderDownVisible(false);
      // console.dir('向下滚动向下滚动向下滚动')
    }
  }
  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false })
    return () => window.removeEventListener('wheel', handleScroll, { passive: false })
  }, [window.scrollY])

  return <div
    className='Header'
    key={key}
  >
    <Link to='/'
      className="Header_logo"
      onClick={() => {
        document.scrollingElement.scrollTop = 0
      }}
    >
      <img
        style={{ width: '100%', height: 'auto' }}
        src={getLogoImg()}
        alt="" />
    </Link>
    <ul className='Header_menu'>
      <li>
        <Link to='/'
          // className='Header_menu-title'
          className={getHeaderMenuTitleClass()}
          style={{}}
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
        >
          首页
        </Link>
      </li>
      <li>
        <Link
          to='/solutionIndex'
          // className={`${getHeaderMenuTitleClass()} ${(location.pathname === '/solutionIndex' || location.pathname === '/arta' || location.pathname === '/argos' || location.pathname === '/eyepal' || location.pathname === '/strabismus' || location.pathname === '/glaucoma') ? 'Header_menu-titleSel' : ''}`}
          className={getHeaderMenuTitleClass()}
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
        >
          产品及解决方案
        </Link>
        <div className='Header_menu-item Header_menu-itemSolution'>
          {solutionData.map((item, index) =>
            <Link
              to={item.router}
              key={index}
              className='Header_menu-itemLink'
              style={{ color: location.pathname === `/${item.router}` ? '#F0B000' : '' }}
              onClick={() => {
                document.scrollingElement.scrollTop = 0
              }}
            >
              {item.name}
            </Link>
          )}
        </div>
      </li>
      <li>
        <Link to='/companyNews'
          // className={`${getHeaderMenuTitleClass()} ${location.pathname === '/companyNews' ? 'Header_menu-titleSel' : ''}`}
          className={getHeaderMenuTitleClass()}
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
        >
          公司动态
        </Link>
      </li>
      <li>
        <Link to='/fcst'
          // className={`${getHeaderMenuTitleClass()} ${(location.pathname === '/fcst' || location.pathname === '/fcst/literature' || location.pathname === '/fcst/sciencePopularization') ? 'Header_menu-titleSel FCSTColor' : ''}`}
          className={getHeaderMenuTitleClass()}
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
        >
          FCST研究院
        </Link>
        <div className='Header_menu-item Header_menu-itemFcst'>
          {FcstDate.map((item, index) =>
            <Link
              to={item.router}
              key={index}
              // style={{ color: location.pathname === `/${item.router}` ? '#F0B000' : '' }}
              style={{ color: location.pathname.toLowerCase().includes(`/${item.router}`.toLowerCase()) ? '#F0B000' : '' }}
              className="Header_menu-itemLink">
              {item.name}
            </Link>)}
        </div>
      </li>
      <li>
        <div
          // className={`${getHeaderMenuTitleClass()} ${(location.pathname === '/companyProfile' || location.pathname === '/contactUs') ? 'Header_menu-titleSel' : ''}`}
          className={getHeaderMenuTitleClass()}
          style={{ cursor: 'default' }}
        >
          关于我们
        </div>
        <div className='Header_menu-item Header_menu-itemAboutUs'>
          <Link
            to='/companyProfile'
            className="Header_menu-itemLink"
            style={{ color: location.pathname === '/companyProfile' ? '#F0B000' : '' }}
            onClick={() => {
              document.scrollingElement.scrollTop = 0
            }}
          >
            公司简介
          </Link>
          <Link
            to='/contactUs'
            className="Header_menu-itemLink"
            style={{ color: location.pathname === '/contactUs' ? '#F0B000' : '' }}
            onClick={() => {
              document.scrollingElement.scrollTop = 0
            }}
          >
            联系我们
          </Link>
        </div>
      </li>
    </ul>
  </div>
}
