import './strabismus.less';

export default function Strabismus() {
  return <div className="Strabismus">
    <div className='Strabismus_cover'>
      <div className="Strabismus_cover-title">斜视术后康复数字疗法</div>
    </div>
    <div className="Strabismus_content">
      <div className="Strabismus_content-title">集视医疗斜视数字疗法</div>
      <div className="Strabismus_content-desc">集视医疗正在开发一种斜视全周期管理方案，涵盖长期的眼动追踪、15度以内的斜视手术替代治疗、术后矫正训练等模块。</div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="Strabismus_content-video">
          <div className='Strabismus_content-videoBgc'></div>
          <video controls style={{ width: '100%', height: '100%', borderRadius: 12, objectFit: 'cover' }}>
            <source src="/video/strabismus.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="Strabismus_content-img2">
          <img
            style={{ width: '100%', height: '100%', borderRadius: 12 }}
            src='./image/strabismus(2).png'
            alt=''
          />
        </div>
      </div>
      <div className="Strabismus_content-groupImgs">
        <div className="Strabismus_content-groupItem">
          <div className="Strabismus_content-groupItemTitle">3D eye tracking initiated</div>
          <div className="Strabismus_content-img3">
            <img
              style={{ width: '100%', height: '100%' }}
              src='./image/strabismus(3).png'
              alt=''
            />
          </div>
        </div>
        <div className="Strabismus_content-groupItem">
          <div className="Strabismus_content-groupItemTitle">Drift Corrected Sphere</div>
          <div className="Strabismus_content-img3">
            <img
              style={{ width: '100%', height: '100%' }}
              src='./image/strabismus(4).png'
              alt=''
            />
          </div>
        </div>
      </div>
    </div>

    {/* demo */}
    {/* <div className='Demo_head'>
      <div className='head-wrapper'>
        <div className='wrapper-title'>Mac Pro</div>
        <div className='wrapper-subtitle'>Transformed by Apple silicon.</div>
      </div>
      <div className='hero-overflow-container'>
        <div className='flex_col ml_40 mr_40' style={{ bottom: 0, position: 'absolute', width: 'calc(100% - 80px)', zIndex: 2 }}>
          <img
            src='/image/hero_endframe__e2jh6ntq84mu_small.jpg'
            style={{ width: '100%', height: 100 }}
            className='ta_c'
          />
          <img
            src='/image/hero_case__ea52dhf7so8y_small.jpg'
            style={{ width: '100%', height: 200 }}
            className='ta_c'
          />
        </div>
      </div>
    </div> */}
    {/* 滚动条从左到右加载，颜色从浅灰到深灰渐变 */}
    {/* <div className="container">
      <div className="scroll-bar"></div>
    </div> */}
  </div>
}
