import { Link } from 'react-router-dom';
import './fcstIndex.less';

export default function Fcst() {
  return <div className="Fcst">
    <div className='Fcst_cover'>
      <div className="Fcst_cover-title">
        FCST研究院
      </div>
    </div>
    <div className="Fcst_literature">
      <span className='Fcst_title'>文献速递</span>
      <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
        <div className='Fcst_desc'>集视医疗在理论和方法均有突破</div>
        <Link
          to='/fcst/literature'
          className='Fcst_btn'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
        >
          查看全部
        </Link>
      </div>
      <div className="Fcst_literature-content">
        <Link
          to='/fcst/literatureinfo/breakthroughTheory'
          className="Fcst_literature-left"
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
        >
          <div className="Fcst_literature-leftBgc"></div>
          <img
            style={{ width: '100%', height: '100%', borderRadius: 12 }}
            src='./image/FCST(1).png'
          />
          <div className='Fcst_literature-leftBtn'>精选文章</div>
          <div className='Fcst_literature-leftTitle'>理论突破<br />弱视的皮层下受损</div>
          <div className='Fcst_literature-leftDate'>11.20</div>
        </Link>
        <Link
          to='/fcst/literatureinfo/breakthroughMethod'
          className="Fcst_literature-left"
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
        >
          <div className="Fcst_literature-leftBgc"></div>
          <img
            style={{ width: '100%', height: '100%', borderRadius: 12 }}
            src='./image/FCST(2).png'
          />
          <div className='Fcst_literature-leftBtn'>精选文章</div>
          <div className='Fcst_literature-leftTitle'>结合增强现实,针对弱视眼P通路进行矫正的</div>
          <div className='Fcst_literature-leftDate'>11.20</div>
        </Link>
      </div>
    </div>
    <div className="Fcst_science">
      <span className='Fcst_title'>科普专栏</span>
      <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
        <div className='Fcst_desc'>一些科普小知识</div>
        <Link
          to='/fcst/sciencePopularization'
          className='Fcst_btn'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
        >
          了解更多
        </Link>
      </div>
      {/* <div className="Fcst_science-contentTop">
        <Link
          to='/fcst/SciencePopularizationInfo/identifyAmblyopia'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          className="Fcst_science-contentTopItem"
        >
          <div className="Fcst_literature-leftBgc"></div>
          <img
            style={{ width: '100%', height: '50%', borderRadius: 12 }}
            src='./image/FCST(3).png'
          />
          <div className='Fcst_science-itemTip'>弱视</div>
          <div className='Fcst_science-itemTitle'>什么是弱视？</div>
          <div className='Fcst_literature-leftDate'>03.25</div>
        </Link>
        <Link
          to=''
          className="Fcst_science-contentTopItem"
        >
          <div className="Fcst_literature-leftBgc"></div>
          <img
            style={{ width: '100%', height: '50%', borderRadius: 12 }}
            src='./image/FCST(4).png'
          />
          <div className='Fcst_science-itemTip'>近视</div>
          <div className='Fcst_science-itemTitle'>近视防控应该怎么做？</div>
        </Link>
        <Link
          to='/fcst/SciencePopularizationInfo/identifyMyopia'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          className="Fcst_science-contentTopItem"
        >
          <div className="Fcst_literature-leftBgc"></div>
          <img
            style={{ width: '100%', height: '50%', borderRadius: 12 }}
            src='./image/FCST(3).png'
          />
          <div className='Fcst_science-itemTip'>近视</div>
          <div className='Fcst_science-itemTitle'>什么是近视？</div>
          <div className='Fcst_literature-leftDate'>04.22</div>
        </Link>
      </div> */}
      <div className="Fcst_science-contentBottom">
        <Link
          to='/fcst/SciencePopularizationInfo/identifyAmblyopia'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          className="Fcst_science-contentBottomItem"
        >
          <div className="Fcst_literature-leftBgc"></div>
          <img
            style={{ width: '100%', height: '60%', }}
            src='./image/FCST(3).png'
          />
          <div className='Fcst_science-bottomTtemTip'>弱视</div>
          <div className='Fcst_science-bottomTtemTitle'>什么是弱视？</div>
          <div className='Fcst_literature-leftDate'>03.25</div>
        </Link>
        <Link
          to='/fcst/SciencePopularizationInfo/chooseMyopiaProduct'
          onClick={() => {
            document.scrollingElement.scrollTop = 0
          }}
          className="Fcst_science-contentBottomItem"
        >
          <div className="Fcst_literature-leftBgc"></div>
          <img
            style={{ width: '100%', height: '60%',  }}
            src='./image/FCST(4).png'
          />
          <div className='Fcst_science-bottomTtemTip'>近视</div>
          <div className='Fcst_science-bottomTtemTitle'>近视防控应该怎么做?</div>
          <div className='Fcst_literature-leftDate'>03.25</div>
        </Link>
      </div>
    </div>
  </div >
}
