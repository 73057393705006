import './companyProfile.less';
import React from 'react';

export default function CompanyProfile() {

  const companyProfileDate = [
    {
      img: '/image/companyProfile(1).png',
      title: '病理机制研究团队',
      team: ['中科院', '香港大学', '清华大学'],
      desc: '与中科院、香港大学、清华大学等顶尖机构强强联合，基于高分辨率fMRI利用功能磁共振从脑科学角度探究眼病病理机制，发现了眼病根本性损伤位点。',
    },
    {
      img: '/image/companyProfile(2).png',
      title: '临床试验团队',
      team: ['复旦眼科', '中山眼科', '温州眼视光医院'],
      desc: <>与复旦眼科、中山眼科、温州眼视光医院等国内头部眼科专家深度合作，打造真正符合临床患者真实需求的产品。<br />产品已进行60+预临床，临床效果得到了充分验证。</>,
    },
    {
      img: '/image/companyProfile(3).png',
      title: '产品开发及注册团队',
      team: ['AI', '可穿戴设备', '...'],
      desc: <>将创新病理机制研究成果与AI、可穿戴设备深度整合。<br />通过脑区损伤位点重塑打造效果更强、依从性更强、可及性更强的创新治疗范式。</>,
    },
  ]

  const eventData = [
    {
      date: '2024',
      desc: '集视医疗“视觉功能训练治疗软件”获批，用于弱视患者的临床治疗',
    },
    {
      date: '2022',
      desc: '苏州集视医疗科技有限公司正式成立，多项重磅学术成果商业化正式开始',
    },
    {
      date: '2021',
      desc: '在学术顶刊《Cell Report》上发表重磅文章《Loss and enhancement of layer-selective signals in geniculostriate and corticotectal pathways of adult human amblyopia》',
    },
    {
      date: '2017',
      desc: '首次双眼治疗尝试，并成为当年ARVO（美国视觉与研究协会）年会热点话题',
    },
    {
      date: '2015',
      desc: '全球首个提出弱视治疗“双机制”学说，获批国自然面上项目2项',
    },
    {
      date: '2013',
      desc: '发表《弱视神经损伤机制研究》中科院1区文章4篇 (Cell子刊，眼科顶刊等',
    },
    {
      date: '2011',
      desc: '基于高分辨率功能磁共振建立了感知神经性眼病认知平台',
    },
  ]

  return <div className="CompanyProfile">
    <div className='CompanyProfile_cover'>
      <div className="CompanyProfile_cover-title">公司简介</div>
    </div>
    <div className="CompanyProfile_section1">
      <div className="CompanyProfile_section1-bgi"></div>
      <div className="CompanyProfile_section1-content">
        <div className="CompanyProfile_section1-contentLeft">公司简介</div>
        <div style={{display: 'flex', justifyContent: 'center', flex: 1}}>
          <div className="CompanyProfile_section1-contentRight">
            集视医疗是一家专注于眼科医疗器械的创新型企业。致力于从底层脑科学+眼病机制研究+可穿戴智能化产品突破传统干预手段年龄、效果、地点、依从性等限制，提高眼病患者干预效果及可及性。
            <br />
            <br />
            公司以脑科学研究为基础载体运用领先的物联网、人工智能与大数据云计算技术，打通院内外及患者全时空全病程数据，全面赋能眼科疾病的诊断、治疗及诊后管理。
          </div>
        </div>
      </div>
    </div>
    <div className="CompanyProfile_section2">
      {companyProfileDate.map((item, index) => <div className="CompanyProfile_section2-item" key={index}>
        <div className="CompanyProfile_section2-itemImg">
          <img
            style={{ width: '100%', height: '100%', borderRadius: 8 }}
            src={item.img}
            alt=""
          />
        </div>
        <div className="CompanyProfile_section2-itemTitle">{item.title}</div>
        <div className="CompanyProfile_section2-itemLine"></div>
        <div className="CompanyProfile_section2-itemTeam">
          {item.team.map((i, teamIndex) => <div key={teamIndex} className='CompanyProfile_section2-team'>
            <div className='CompanyProfile_section2-teamIcon'>
              <img
                style={{ width: '100%', height: 'auto', }}
                src='./image/icon.png'
                alt=''
              />
            </div>
            <div className='CompanyProfile_section2-teamDesc'>{i}</div>
          </div>)}
        </div>
        <div className="CompanyProfile_section2-itemDesc">{item.desc}</div>
      </div>)}
    </div>
    <div className="CompanyProfile_section3">
      <div className="CompanyProfile_section3-bgi1"></div>
      <div className="CompanyProfile_section3-bgi2"></div>
      <div className="CompanyProfile_section3-bgi3"></div>
      <div className="CompanyProfile_section3-title">重要事件</div>
      <div className="CompanyProfile_section3-content">
        <div className="CompanyProfile_section3-contentLeft">
          {eventData.filter((_, index) => index % 2 === 0).map((i, index) =>
            <div className="CompanyProfile_section3-contentLeftItem" key={index}>
              <div className="CompanyProfile_section3-itemDate">{i.date}</div>
              <div className="CompanyProfile_section3-itemCircle"></div>
              <div className="CompanyProfile_section3-itemLine"></div>
              <div className="CompanyProfile_section3-itemDesc">{i.desc}</div>
            </div>)}
        </div>
        <div className="CompanyProfile_section3-contentLine1"></div>
        <div className="CompanyProfile_section3-contentRight">
          {eventData.filter((_, index) => index % 2 !== 0).map((i, index) =>
            <div className="CompanyProfile_section3-contentRightItem" key={index}>
              <div className="CompanyProfile_section3-RightItemDate">{i.date}</div>
              <div className="CompanyProfile_section3-RightItemCircle"></div>
              <div className="CompanyProfile_section3-RightItemLine"></div>
              <div className="CompanyProfile_section3-RightItemDesc">{i.desc}</div>
            </div>)}
        </div>
      </div>
    </div>
  </div>
}
