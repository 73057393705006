import './argos.less';

export default function Argos() {
  return <div className="Argos">
    <div className='Argos_cover'>
      <div className="Argos_cover-title">
        ARGOS 爱格视™
      </div>
    </div>
    <div className="Argos_contentArgos">
      {/* <div className='Argos_contentArgos-top'> */}
      <div className='Argos_contentArgos-title'>ARGOS 爱格视™</div>
      <div className='Argos_contentArgos-subTitle'>三大功能、眼脑协同</div>
      {/* </div> */}
      <div className='Argos_contentArgos-bottom'>
        <div className='Argos_contentArgos-bottomBgi'>
          <img
            style={{ width: '100%', height: '100%', }}
            src='./image/bgi1.png'
            alt=''
          />
        </div>
        <div className='Argos_contentArgos-bottomBg2'></div>
        <div className='Argos_contentArgos-bottomGroup'>
          <div className='Argos_contentArgos-bottomItem'>
            <div className='Argos_contentArgos-bottomIcon'>
              <img
                style={{ width: '100%', height: '100%' }}
                src='./image/argosIcon(1).png'
                alt=''
              />
            </div>
            <div className='Argos_contentArgos-bottomTitle'>便携</div>
            <div className='Argos_contentArgos-bottomDesc'>
              更“便携”的光学<br />远像技术
            </div>
          </div>
          <div className='Argos_contentArgos-bottomItem'>
            <div className='Argos_contentArgos-bottomIcon'>
              <img
                style={{ width: '100%', height: '100%' }}
                src='./image/argosIcon(2).png'
                alt=''
              />
            </div>
            <div className='Argos_contentArgos-bottomTitle'>趣味</div>
            <div className='Argos_contentArgos-bottomDesc'>
              更“趣味”的3D<br />视觉训练
            </div>
          </div>
          <div className='Argos_contentArgos-bottomItem'>
            <div className='Argos_contentArgos-bottomIcon'>
              <img
                style={{ width: '100%', height: '100%' }}
                src='./image/argosIcon(3).png'
                alt=''
              />
            </div>
            <div className='Argos_contentArgos-bottomTitle'>科学</div>
            <div className='Argos_contentArgos-bottomDesc'>
              更“科学”的脑视觉<br />锐化训练
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="Argos_portable">
      <div className="Argos_portable-left">
        <div className="Argos_portable-leftTitle">更“便携”的光学远像技术</div>
        <div className='Argos_portable-leftItem mt_70'>
          {/* <div className='Argos_portable-leftIcon'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/icon.png'
              alt=''
            />
          </div> */}
          <div className='Argos_portable-item1Desc'>近距离用眼<br />30CM</div>
          <div className='Argos_portable-leftArrow'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/rightArrow.png'
              alt=''
            />
          </div>
          <div className='Argos_portable-item1Desc ml_20'>远距离巨幕<br />5M</div>
        </div>
        <div className='Argos_portable-leftItem'>
          {/* <div className='Argos_portable-leftIcon'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/icon.png'
              alt=''
            />
          </div> */}
          <div className='Argos_portable-item2Desc'>看书、网课、娱乐全方位防控，用眼=护眼</div>
        </div>
        <div className='Argos_portable-leftItem'>
          {/* <div className='Argos_portable-leftIcon'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/icon.png'
              alt=''
            />
          </div> */}
          <div className='Argos_portable-item2Desc'>轻便易用，适用于多种场景</div>
        </div>
      </div>
      <div className="Argos_portable-right">
        <div className='Argos_portable-rightBgi1'>
          <img
            style={{ width: '100%', height: '100%', }}
            src='./image/bgi1.png'
            alt=''
          />
        </div>
        <div className='Argos_portable-rightBgi2'>
          <img
            style={{ width: '100%', height: '100%', }}
            src='./image/bgi2.png'
            alt=''
          />
        </div>
        <div className='Argos_portable-rightBgi3'></div>
        <div className='Argos_portable-rightBgi4'></div>
        <div className='Argos_portable-rightImg'>
          <img
            style={{ width: '100%', height: '100%', borderRadius: 12 }}
            src='./image/argos(2).png'
            alt=''
          />
        </div>
      </div>
    </div>
    <div className="Argos_interest">
      <div className='Argos_interest-left'>
        <div className='Argos_interest-leftBgi1'>
          <img
            style={{ width: '100%', height: '100%', }}
            src='./image/bgi1.png'
            alt=''
          />
        </div>
        <div className='Argos_interest-leftBgi2'>
          <img
            style={{ width: '100%', height: '100%', }}
            src='./image/bgi2.png'
            alt=''
          />
        </div>
        {/* <div className='Argos_interest-leftBgi3'></div> */}
        <div className='Argos_interest-leftBgi4'></div>
        <div className='Argos_interest-leftImg'>
          <img
            style={{ width: '100%', height: '100%', borderRadius: 12 }}
            src='./image/argos(3).png'
            alt=''
          />
        </div>
      </div>
      <div className='Argos_interest-right'>
        <div className="Argos_interest-rightTitle">更“趣味”的3D视觉训练</div>
        <div className="Argos_interest-rightContent">
          {/* <div className="Argos_interest-rightItem mt_70">多样化的眼动训练，放松睫状肌，调节视功能</div>
          <div className="Argos_interest-rightItem">内置15款眼动训练游戏，更具趣味性</div>
          <div className="Argos_interest-rightItem">内容涵盖立体视、调节力、视觉理解记忆等全方位视觉训练</div> */}
          <div className='Argos_portable-leftItem mt_70'>
            {/* <div className='Argos_portable-leftIcon'>
            <img
              style={{ width: '100%', height: '100%', }}
              src='./image/icon.png'
              alt=''
            />
          </div> */}
            <div className='Argos_portable-item2Desc'>多样化的眼动训练，放松睫状肌，调节视功能</div>
          </div>
          <div className='Argos_portable-leftItem'>
            {/* <div className='Argos_portable-leftIcon'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/icon.png'
              alt=''
            />
          </div> */}
            <div className='Argos_portable-item2Desc'>内置15款眼动训练游戏，更具趣味性</div>
          </div>
          <div className='Argos_portable-leftItem'>
            {/* <div className='Argos_portable-leftIcon'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/icon.png'
              alt=''
            />
          </div> */}
            <div className='Argos_portable-item2Desc'>内容涵盖立体视、调节力、视觉理解记忆等全方位视觉训练</div>
          </div>
        </div>
      </div>
      <div className='Argos_interest-leftBgi3'></div>
    </div>
    <div className="Argos_science">
      <div className='Argos_science-rightBgi2'></div>
      <div className="Argos_science-content">
        <div className="Argos_science-left">
          <div className="Argos_science-leftTitle">更“科学”的脑视觉锐化训练</div>
          <div className='Argos_portable-leftItem mt_70'>
            {/* <div className='Argos_portable-leftIcon'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/icon.png'
              alt=''
            />
          </div> */}
            <div className='Argos_portable-item2Desc'>直接提升裸眼/矫正视力</div>
          </div>
          <div className='Argos_portable-leftItem'>
            {/* <div className='Argos_portable-leftIcon'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/icon.png'
              alt=''
            />
          </div> */}
            <div className='Argos_portable-item2Desc'>唯一一款融合脑视觉的训练方案</div>
          </div>
          <div className='Argos_portable-leftItem'>
            {/* <div className='Argos_portable-leftIcon'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/icon.png'
              alt=''
            />
          </div> */}
            <div className='Argos_portable-item2Desc'>定向锻炼视觉通路对高空间频率信号的敏感性</div>
          </div>
        </div>
        <div className="Argos_science-right">
          <div className='Argos_science-rightBgi1'>
            <img
              style={{ width: '100%', height: 'auto', }}
              src='./image/bgi1.png'
              alt=''
            />
          </div>
          {/* <div className='Argos_science-rightBgi2'></div> */}
          <div className='Argos_science-rightBgi3'></div>
          <div className='Argos_science-rightImg'>
            <div className="Argos_science-rightImgBgc"></div>
            <img
              style={{ width: '100%', height: '100%', borderRadius: 12 }}
              src='./image/argos(4).png'
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
    <div className='Argos_bottom'>
      <div className='Argos_bottom-img'>
        <img
          style={{ width: '100%', height: '100%', }}
          src='./image/argos(1).png'
          alt=''
        />
      </div>
    </div>
  </div >
}