import { Link } from 'react-router-dom';
import './sciencePopularizationInfo.less';

export default function IdentifyMyopia() {
  return <div className="SciencePopularizationInfo">
    <div className='SciencePopularizationInfo_bgc1'></div>
    <div className='SciencePopularizationInfo_bgc2'></div>
    <div className='SciencePopularizationInfo_bgc3'></div>
    <div className='SciencePopularizationInfo_bgc4'></div>
    <div className='SciencePopularizationInfo_bgc5'></div>
    <div className="SciencePopularizationInfo_content">
      <Link
        to='/fcst/sciencePopularization'
        className="SciencePopularizationInfo_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有科普文章
      </Link>
      <div className="SciencePopularizationInfo_content-title">【科普】近视防控应该怎么做?</div>
      <div className="SciencePopularizationInfo_content-date">2024-10-31</div>
      <div className="SciencePopularizationInfo_content-line"></div>
      <div className="SciencePopularizationInfo_content-subTitle">孩子出现这些症状，可能是近视了</div>
      <div className="SciencePopularizationInfo_content-desc">
        1.眯起眼来看东西；<br />
        2.频繁揉眼睛、眨眼；<br />
        3.看东西需要凑近才能看清。<br />
        若孩子出现上述表现，可能是近视了，建议尽早带孩子去医院检查视力并矫正。
      </div>
      <div className="SciencePopularizationInfo_content-subTitle">如何保护孩子视力?</div>
      <div className="SciencePopularizationInfo_content-desc">
        儿童青少年预防近视首先要坚持户外运动坚持每天日间户外活动不少于2小时。<br />
        0-3岁婴幼儿不使用手机、平板、电脑等视屏类电子产品，3-6岁幼儿尽量避免接触和使用视屏类电子产品，中小学生每天累计视屏时长不宜超过1小时。<br />
        要保持与屏幕适宜的观看距离。电子屏幕的大小不同，适合观看的距离就不同。应根据房间大小、观看距离来选择屏幕尺寸。<br />
        缓解眼睛疲劳，可使用“202020法则":近距离用眼20分钟应该远跳20英尺(约6米)，不少于20秒。<br />
        读写的姿势要做到“三个一":手离笔尖一寸，眼离书本一尺，还有前胸离桌缘一拳。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="SciencePopularizationInfo_content-img">
          <img
            style={{ width: '100%', height: '100%' }}
            src='/image/identifyMyopia1.png'
            alt=""
          />
        </div>
      </div>
      <div className="SciencePopularizationInfo_content-subTitle">孩子已经近视了怎么办？</div>
      <div className="SciencePopularizationInfo_content-desc">
        首先判断是真性近视还是假性近视。<br />
        假性近视<br />
        如果孩子出现"假性近视”，可以让孩子眼睛彻底放松，使眼睛慢慢恢复，如减少近距离用眼等。<br />
        真性近视<br />
        一方面要根据医生和验光师的建议，为孩子及时佩戴眼镜矫正近视。同时要注意加强日间户外活动，减少近距离用眼和电子产品使用，以减少近视发展。同时要每3-6个月到医院复查视力。
      </div>
      <div className="SciencePopularizationInfo_content-subTitle">近视防控产品应该怎么选择？</div>
      <div className="SciencePopularizationInfo_content-desc">
        严格意义上来说，近视防控应该分为两类，一个是“防”——阻止近视发生，另一个是“控”——控制近视程度增长，但是目前市场上主流的方案都是用于近视度数增长的控制。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="SciencePopularizationInfo_content-img">
          <img
            style={{ width: '100%', height: '100%' }}
            src='/image/identifyMyopia2.png'
            alt=""
          />
        </div>
      </div>
      <div className="SciencePopularizationInfo_content-descTop">
        目前比较常见的OK镜和阿托品都需要在专业的眼科医生的指导下进行使用，均能在一定程度上控制眼轴的症状，延缓近视度数的加深，但是对已经近视的患者并不能起到“摘镜”的作用；<br /><br />
        年龄较小的近视患者切记盲目进行近视手术，因为未成年人眼部发育未完善，眼球仍处于生长发育阶段，且近视度数不稳定，若盲目接受激光近视手术，首先降低了激光近视手术的安全性，其次术后易出现视力回退、反弹等并发症。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="SciencePopularizationInfo_content-img">
          <img
            style={{ width: '100%', height: '100%' }}
            src='/image/identifyMyopia3.png'
            alt=""
          />
        </div>
      </div>
      <div className="SciencePopularizationInfo_content-descTop">
        爱格视™是一款融合三大功能，眼球+脑视觉双通路防控近视，更全面的近视防控产品。<br />
        其功能包括：<br />
        光学远像技术，能够实现看近等同于看远，有效放松睫状肌，缓解视疲劳；<br />
        3D立体视训练，通过游戏化的方式训练，有效提升孩子调节功能；<br />
        脑视觉锐化训练，是目前唯一一款用于近视患者的脑视觉训练方案，可直接提升裸眼/矫正视力。
      </div>
      <div className="SciencePopularizationInfo_content-line"></div>
    </div>
  </div>
}
