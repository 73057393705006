import './contactUs.less';

export default function ContactUs() {
  const addressData = [
    {
      title: '苏州集视医疗科技有限公司',
      sddress: <>
        Suzhou Focusight Medical<br /> Technology Co., Ltd<br />
        中国(江苏)自由贸易试验区苏州片区苏州工业园区现代大道88号物流大厦
      </>,
      email: 'public@focusight.ltd',
    },
    {
      title: '杭州集视医疗科技有限公司',
      sddress: <>
        Hangzhou Focusight Intelligent<br /> Technology Co., Ltd<br />
        浙江省杭州市上城区源聚路299号钱涌白石中心2幢
      </>,
      email: 'public@focusight.ltd',
    },
  ]

  return <div className="ContactUs">
    <div className='ContactUs_cover'>
      <div className="ContactUs_cover-title">联系我们</div>
      <div className="ContactUs_cover-subTitle">如果您需要我们的帮助、对如何使用平台有疑问或遇到技术困难，请随时与我们联系</div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="ContactUs_address">
        <div className='ContactUs_address-bgi1'>
          <img
            style={{ width: '100%', height: 'auto', }}
            src='./image/bgi1.png'
            alt=''
          />
        </div>
        <div className="ContactUs_address-bgi2"></div>
        <div className='ContactUs_address-top'>
          <div className="ContactUs_address-title">公司地址</div>
          <div className="ContactUs_address-desc">如果您在我们的常见问题解答中找不到您的问题的答案，您可以随时联系我们。 我们会尽快回复您！</div>
        </div>
        <div className="ContactUs_address-bottom">
          {addressData.map((item, index) => <div key={index} className="ContactUs_address-bottomItem">
            <div className='ContactUs_address-itemImg'>
              <img
                style={{ width: '100%', height: 'auto', }}
                src='./image/addressIcon.png'
                alt=''
              />
            </div>
            <div className="ContactUs_address-itemTitle">{item.title}</div>
            <div className="ContactUs_address-itemAddress">{item.sddress}</div>
            <div className="ContactUs_address-itemEmail">{item.email}</div>
          </div>)}
        </div>
      </div>
    </div>
  </div>
}
