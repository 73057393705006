import './academic.less';
import { Link } from 'react-router-dom';

export default function Academic() {
  return <div className="Academic">
    <div className='Academic_bgc1'></div>
    <div className='Academic_bgc2'></div>
    <div className='Academic_bgc3'></div>
    <div className='Academic_bgc4'></div>
    <div className='Academic_bgc5'></div>
    <div className="Academic_content">
      <Link
        to='/allNews'
        className="Academic_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有动态
      </Link>
      <div className="Academic_content-title">集视医疗亮相中华医学会第二十八次眼科学术大会</div>
      <div className="Academic_content-date">2024-09-08</div>
      <div className="Academic_content-line"></div>
      <div className="Academic_content-desc1">
        2024年9月4-8日，中华医学会第二十八次眼科学术大会在武汉国际博览中心隆重举行，作为年度眼科行业最盛大的学术会议，全球逾万名专家、学者相聚于此，交流分享眼科和视觉科学方面最新研究成果
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='Academic_content-img1'
          src='/image/academic1.png'
          alt=""
        />
      </div>
      <div className="Academic_content-desc1">
        集视医疗十年磨一剑，多款眼科创新型产品：埃尔塔™全年龄弱视治疗仪、爱格视™多功能3D近视训练仪、眼派™可视温润舒眼仪首次亮相眼科年会。展示了全新的弱视治疗、近视防控和干眼护理的解决方案，为眼科诊疗范式革新注入一股强劲的“中国力量”！
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='Academic_content-img2'
          src='/image/academic2.png'
          alt=""
        />
      </div>
      <div className="Academic_content-desc1">
        会议期间，集视医疗展示的产品吸引了众多专家学者和商业投资领域的访客前来交流，就临床治疗方案的革新和产品设计理念进行了深入的研讨，收获了各领域大咖的一致肯定。
      </div>
      <div className="Academic_content-desc1">
        2024CCOS是集视医疗首次参与眼科临床的学术交流，集视医疗将以2024全国眼科年会为起点，进一步加强与医生、学者的沟通和交流，旨在以开放合作的心态为行业赋能，共同推进我国眼科行业的健康发展。
      </div>
      <div className="Academic_content-line"></div>
    </div>
  </div>
}
