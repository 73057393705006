import './eyepal.less';

export default function Eyepal() {
  return <div className="Eyepal">
    <div className='Eyepal_cover'>
      <div className="Eyepal_cover-title">
        EYEPAL 眼派™
      </div>
    </div>
    <div className='Eyepal_section1'>
      <div className="Eyepal_section1-bgi1">
        <img
          style={{ width: '100%', height: 'auto', }}
          src='./image/bgi1.png'
          alt=''
        />
      </div>
      <div className="Eyepal_section1-bgi2"></div>
      <div className="Eyepal_section1-img">
        <div className="Eyepal_section1-imgBgc"></div>
        <img
          style={{ width: '100%', height: '100%', }}
          src='./image/eyepal(1).png'
          alt=''
        />
      </div>
    </div>
    <div className="Eyepal_section2">
      <div className="Eyepal_section2-title">EYEPAL眼派™</div>
      <div className='Eyepal_section2-item'>
        <div className='Eyepal_section2-itemIcon'>
          <img
            style={{ width: '100%', height: 'auto', }}
            src='./image/icon.png'
            alt=''
          />
        </div>
        <div className='Eyepal_section2-itemDesc'>有效增加泪河高度</div>
      </div>
      <div className='Eyepal_section2-item'>
        <div className='Eyepal_section2-itemIcon'>
          <img
            style={{ width: '100%', height: 'auto', }}
            src='./image/icon.png'
            alt=''
          />
        </div>
        <div className='Eyepal_section2-itemDesc'>延长泪膜破裂时间</div>
      </div>
      <div className='Eyepal_section2-item'>
        <div className='Eyepal_section2-itemIcon'>
          <img
            style={{ width: '100%', height: 'auto', }}
            src='./image/icon.png'
            alt=''
          />
        </div>
        <div className='Eyepal_section2-itemDesc'>增加泪膜脂层厚度</div>
      </div>
    </div>
    <div className="Eyepal_section3">
      <div className="Eyepal_section3-bgi1"></div>
      <div className="Eyepal_section3-bgi2">
        <img
          style={{ width: '100%', height: 'auto', }}
          src='./image/bgi1.png'
          alt=''
        />
      </div>
      <div className="Eyepal_section3-content">
        <div className="Eyepal_section3-contentImg1">
          <img
            style={{ width: '100%', height: '100%', borderRadius: 12 }}
            src='./image/eyepal(2).png'
            alt=''
          />
        </div>
        <div className="Eyepal_section3-contentImg2">
          <img
            style={{ width: '100%', height: '100%', borderRadius: 12 }}
            src='./image/eyepal(3).png'
            alt=''
          />
        </div>
      </div>
    </div>
  </div>
}