// import Router from 'react-easy-router';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Header from "./components/Header/Header";
import Header from "./components/Header/Header2";
import Footer from "./components/Footer/Footer";
import Index from "./pages/Index/Index";
import SolutionIndex from "./pages/Solution/SolutionIndex/solutionIndex";
import Arta from "./pages/Solution/Arta/arta";
import Argos from "./pages/Solution/Argos/argos";
import Eyepal from "./pages/Solution/Eyepal/eyepal";
import Glaucoma from "./pages/Solution/Glaucoma/glaucoma";
import Strabismus from "./pages/Solution/strabismus/strabismus";
import ContactUs from "./pages/AboutUs/ContactUs/contactUs";
import CompanyProfile from "./pages/AboutUs/CompanyProfile/companyProfile";
import CompanyNews from "./pages/CompanyNews/companyNews";
import AllNews from "./pages/NewsInfo/AllNews/allNews";
import Contract from "./pages/NewsInfo/Contract/contract";
import Arvo from "./pages/NewsInfo/Arvo/arvo";
import Pre from "./pages/NewsInfo/Pre-A/pre-a";
import AmblyopiaClinical from "./pages/NewsInfo/AmblyopiaClinical/amblyopiaClinical";
import Invest from "./pages/NewsInfo/Invest/invest";
import BestEnterprise from "./pages/NewsInfo/BestEnterprise/bestEnterprise";
import Academic from "./pages/NewsInfo/Academic/academic";
import FcstIndex from "./pages/Fcst/FcstIndex/fcstIndex";
import Literature from "./pages/Fcst/Literature/literature";
import SciencePopularization from "./pages/Fcst/SciencePopularization/sciencePopularization";
import BreakthroughTheory from "./pages/Fcst/LiteratureInfo/breakthroughTheory";
import BreakthroughMethod from "./pages/Fcst/LiteratureInfo/breakthroughMethod";
import IdentifyAmblyopia from "./pages/Fcst/SciencePopularizationInfo/identifyAmblyopia";
import IdentifyMyopia from "./pages/Fcst/SciencePopularizationInfo/identifyMyopia";
import TreatAmblyopia from "./pages/Fcst/SciencePopularizationInfo/treatAmblyopia";
import ChooseMyopiaProduct from "./pages/Fcst/SciencePopularizationInfo/chooseMyopiaProduct";
import './App.css';

export default function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <Header />
        <Routes>
          {/* <Route exact path="/" component={Index} />
        <Route exact path="/solution" component={Solution} /> */}
          <Route exact path="/" element={<Index />} />
          <Route exact path="/solutionIndex" element={<SolutionIndex />} />
          <Route exact path="/arta" element={<Arta />} />
          <Route exact path="/argos" element={<Argos />} />
          <Route exact path="/eyepal" element={<Eyepal />} />
          <Route exact path="/glaucoma" element={<Glaucoma />} />
          <Route exact path="/strabismus" element={<Strabismus />} />
          <Route exact path="/contactUs" element={<ContactUs />} />
          <Route exact path="/companyProfile" element={<CompanyProfile />} />
          <Route exact path="/companyNews" element={<CompanyNews />} />
          <Route exact path="/allNews" element={<AllNews />} />
          <Route exact path="/newsInfo/contract" element={<Contract />} />
          <Route exact path="/newsInfo/arvo" element={<Arvo />} />
          <Route exact path="/newsInfo/pre" element={<Pre />} />
          <Route exact path="/newsInfo/amblyopiaClinical" element={<AmblyopiaClinical />} />
          <Route exact path="/newsInfo/invest" element={<Invest />} />
          <Route exact path="/newsInfo/bestEnterprise" element={<BestEnterprise />} />
          <Route exact path="/newsInfo/academic" element={<Academic />} />
          <Route exact path="/fcst" element={<FcstIndex />} />
          <Route exact path="/fcst/literature" element={<Literature />} />
          <Route exact path="/fcst/sciencePopularization" element={<SciencePopularization />} />
          <Route exact path="/fcst/literatureinfo/breakthroughTheory" element={<BreakthroughTheory />} />
          <Route exact path="/fcst/literatureinfo/breakthroughMethod" element={<BreakthroughMethod />} />
          <Route exact path="/fcst/SciencePopularizationInfo/identifyAmblyopia" element={<IdentifyAmblyopia />} />
          <Route exact path="/fcst/SciencePopularizationInfo/identifyMyopia" element={<IdentifyMyopia />} />
          <Route exact path="/fcst/SciencePopularizationInfo/treatAmblyopia" element={<TreatAmblyopia />} />
          <Route exact path="/fcst/SciencePopularizationInfo/chooseMyopiaProduct" element={<ChooseMyopiaProduct />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
