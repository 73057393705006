import { Link } from 'react-router-dom';
import './pre-a.less';

export default function PreA() {
  return <div className="PreA">
    <div className='PreA_bgc1'></div>
    <div className='PreA_bgc2'></div>
    <div className='PreA_bgc3'></div>
    <div className='PreA_bgc4'></div>
    <div className='PreA_bgc5'></div>
    <div className="PreA_content">
      <Link
        to='/allNews'
        className="PreA_content-return"
        style={{ textDecoration: 'none' }}
        onClick={() => {
          document.scrollingElement.scrollTop = 0
        }}
      >
        <img
          src='/image/leftArrow.png'
          alt=''
        />
        查看所有动态
      </Link>
      <div className="PreA_content-title">集视医疗完成Pre-A轮融资</div>
      <div className="PreA_content-date">2024-09-08</div>
      <div className="PreA_content-line"></div>
      <div className="PreA_content-descTop">
        长久以来，眼科界普遍认为“12岁是弱视治疗的黄金分界线”。随着年龄增长，错过视觉敏感期（12岁以下）的弱视患者疗效较差，视力恢复难度大幅增加，甚至可能造成终生视觉缺陷。然而，据国家《弱视诊治指南》，无论患者的年龄大小都应当进行治疗。<br />
        动脉网获悉，苏州集视医疗科技有限公司（以下简称“集视医疗”）于国内由复旦大学附属眼耳鼻喉科医院牵头，联合多中心开展的全球首个“基于AR技术的全年龄段弱视治疗方案的有效性和安全性”的前瞻性、随机对照临床试验，同时也是全世界首张优效于治疗金标准的弱视治疗方法注册，有望成功打破了“大龄弱视不可治”这一传统认知壁垒。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='PreA_content-img1'
          src='/image/preA(1).png'
          alt=""
        />
      </div>
      <div className="PreA_content-descTop">
        实际上2022年在复旦大学附属眼耳鼻喉科医院开展的IIT研究已证实该方案在大龄弱视治疗中的有效性，试验结果显示：入组平均年龄28岁的患者6个月临床有效率高达77.3%（平均提高3行），不仅填补了传统方法在这一年龄段无法治疗的空白，甚至远高于传统金标准遮盖疗法在儿童阶段的有效率数据（62%以下，平均提高1.8行），且试验中经短时间治疗后已有部分患者实现治愈，患者依从性高达97%以上。
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className='PreA_content-img2'
          src='/image/pre1.png'
          alt=""
        />
      </div>
      <div className="PreA_content-descTop">
        据悉，集视医疗已于近期完成由复健资本独家投资的Pre-A轮融资。随着本轮资金的成功注入，集视医疗将大幅提升产能，不断巩固和拓展其全年龄段弱视治疗方案，扎实推进已获批产品的商业化进程，并加快在斜视、青光眼、老视等储备管线的产品落地。
        目前集视医疗已与复旦大学附属眼耳鼻喉科医院、温州医科大学附属眼视光医院、中山大学附属眼科医院等国内顶尖眼科平台建立产学研深度合作，致力于为患者提供更加精准有效的治疗方案。
      </div>
      <div className="PreA_content-line"></div>
    </div>
  </div>
}
